import Lottie from 'react-lottie';
import animatedT from '../../animations/animatedT.json';

interface AnimatedTProps {
  height?: number;
  width?: number;
  loop?: boolean;
  autoplay?: boolean;
}

/**
 * T shaped loader. Full animation is 4800ms
 */
const AnimatedT: React.FC<AnimatedTProps> = ({
  height = 89,
  width = 82,
  loop = true,
  autoplay = true,
}) => (
  <Lottie
    options={{
      loop,
      autoplay,
      animationData: animatedT,
    }}
    height={height}
    width={width}
  />
);

export default AnimatedT;
