import { useState, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { geocodeByAddress } from 'react-places-autocomplete';

import SearchGooglePlacesInput from '../SearchGooglePlacesInput/SearchGooglePlacesInput';

const parseAddress = (
  // eslint-disable-next-line camelcase
  addressComponents: { long_name: string; short_name: string; types: string[] }[]
): Record<string, string> => {
  let result: Record<string, string> = {};

  addressComponents.forEach(({ long_name: longName, short_name: shortName, types }) => {
    // state
    if (types.includes('administrative_area_level_1')) {
      result = { ...result, state: shortName };
    }
    // zipcode
    if (types.includes('postal_code')) {
      result = { ...result, zipcode: longName };
    }
    // country
    if (types.includes('country')) {
      result = { ...result, country: shortName };
    }
  });

  return result;
};

interface Props {
  onSelect?: (parsedAddress: Record<string, string>) => void;
}
const ZipcodeSearch = ({ onSelect }: Props) => {
  const {
    setValue: setFormValue,
    formState: { errors },
  } = useFormContext();

  const zipcodeName = 'zipcode';

  const [searchValue, setSearchValue] = useState<string>('');

  const handleAddressSelect = useCallback(
    (firstAddress: string) => {
      geocodeByAddress(firstAddress).then((results) => {
        const [{ address_components: addressComponents = [] } = {}] = results;
        const parsedAddress = parseAddress(addressComponents);
        Object.keys(parsedAddress).forEach((component) => {
          if (component === 'zipcode') {
            setSearchValue(parsedAddress[component]);
          }
          setFormValue(component, parsedAddress[component]);
        });
        if (onSelect) {
          onSelect(parsedAddress);
        }
      });
    },
    [onSelect, setFormValue]
  );
  const handleSearchValueChange = useCallback(
    (value: string) => {
      setSearchValue(value);
      setFormValue(zipcodeName, value);
    },
    [setFormValue]
  );
  const handleOnBlur = useCallback(() => {
    // if typed in the input and did not select anything, handle the typed text as selected option
    handleAddressSelect(searchValue);
  }, [handleAddressSelect, searchValue]);
  return (
    <SearchGooglePlacesInput
      label="Zip code"
      placeholder="Enter zip code"
      dataQa="zipcodeSearchInput"
      value={searchValue}
      searchOptions={{
        componentRestrictions: { country: 'us' },
        types: ['postal_code'],
      }}
      onChange={handleSearchValueChange}
      onSelect={handleAddressSelect}
      onBlur={handleOnBlur}
      error={errors[zipcodeName]}
    />
  );
};

export default ZipcodeSearch;
