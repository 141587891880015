import { v4 as uuidv4 } from 'uuid';
import { TherapistReviewData } from 'ts-frontend/types';
import { WizardStep } from '../types';

export interface WizardCompletedStep {
  name: string;
  value?: any;
  additionalText?: string;
  additionalData?: Record<string, unknown>;
  doAction?: boolean;
  skip?: boolean;
  createdAt: string;
  restored: boolean;
}

export interface WizardResponses {
  steps: WizardCompletedStep[];
  lastStepName: string;
  completed: boolean;
  source: string;
  contextID: string;
  error?: string;
}

export interface RedirectParams {
  redirectURL?: string;
  fullReload?: boolean;
  actionDispatchWithLocationState?: boolean;
  persistOnDispatch?: boolean;
}
type ActionType = 'openAppReview' | 'prefillChatMessage';

export interface AdditionalMetaData {
  actionType?: ActionType;
  therapistRating?: string;
  message?: string;
}

export interface BaseWizardState {
  setStepValueCallback: ((value: any) => void) | null;
  currentDefaultStepValue: any;
  responses: WizardResponses;
  responsesManualOrder?: string[];
  source: string;
  currentStepNumber: number;
  isError: boolean;
  isLoading: boolean;
  contextID: string;
  wizardType: string;
  wizardVersion: number;
  therapistReview?: TherapistReviewData;
  eventCategory?: string;
  alreadyCompleted?: boolean;
  completedAtDate?: string;
  isInitialized: boolean;
  isRestoringAnswers: boolean;
  persisted: boolean;
  disablePersist?: boolean;
  roomID?: number;
  clientUserID?: number;
  shouldPersist?: boolean;
  redirectParams?: RedirectParams;
  isSuccess?: boolean;
  isClosable: boolean;
  hideHeader?: boolean;
}

export interface WizardPostActionContext<T extends BaseWizardState> {
  step: WizardStep;
  wizardContext: T;
  isRepeatedAnswer: boolean;
  setState: (state: Partial<T>) => void;
}

export type WizardPostAction<T extends BaseWizardState, V = string> = (
  value: V,
  context: WizardPostActionContext<T>
) => void;

// Align with `talkspace-api/app/accounts/models/WizardResults`
export type WizardType =
  | 'switchTherapist'
  | 'emergencyContact'
  | 'mentalIntake'
  | 'medicalIntake'
  | 'cancellation'
  | 'matchingIntake'
  | 'matchingIntakePsych'
  | 'refund'
  | 'checkIn'
  | 'financialAid'
  | 'surveyWizard'
  | 'b2bCancellation'
  | 'noMatchesCancellation'
  | 'therapistLeaving'
  | 'nps';

export type WizardModalTitle =
  | 'change provider'
  | 'emergency contact'
  | 'mental intake'
  | 'medical intake'
  | 'matching intake'
  | 'refund'
  | 'financial aid'
  | 'check in'
  | 'survey'
  | 'cancel plan'
  | 'Provider leaving'
  | 'NPS';

export const STEP_NUMBER_WELCOME_SCREEN = -1;

export const baseWizardInitialState = (
  source: string,
  contextID?: string,
  customResponseData?: any
): BaseWizardState => {
  const uuid = contextID || uuidv4();
  return {
    responses: {
      steps: [],
      lastStepName: '',
      completed: false,
      contextID: uuid,
      source,
      ...customResponseData,
    },
    responsesManualOrder: undefined,
    source,
    contextID: uuid,
    currentStepNumber: STEP_NUMBER_WELCOME_SCREEN,
    isError: false,
    isLoading: true,
    wizardType: '',
    wizardVersion: 0,
    therapistReview: undefined,
    eventCategory: '',
    alreadyCompleted: undefined,
    completedAtDate: undefined,
    isInitialized: false,
    isRestoringAnswers: false,
    persisted: false,
    shouldPersist: false,
    redirectParams: undefined,
    isSuccess: false,
    isClosable: true,
    hideHeader: false,
    setStepValueCallback: null,
    currentDefaultStepValue: null,
  };
};
