/* eslint-disable camelcase */
import {
  InsuranceEligibility,
  ClientMatchPresentingProblem,
  InsuranceEligibilityPayer,
  ImplicitBusinessHoursByDay,
  ServiceType,
} from 'ts-frontend/types';
import { TFQuickmatchCommon } from '@talkspace/i18n/types';
import { PromiseMessageQMInitDataReceiver } from 'ts-promise-message';
import type {
  FlowConfig,
  FlowStep,
  InsuranceConfirmation,
  NextQuestionAction,
  NextQuestionActionForTarget,
  QMFlow,
  QuickStatusAction,
} from '../../Flows/types';
import {
  PLAN_REVIEW_BH,
  PLAN_REVIEW_EAP_MESSAGE,
  PLAN_REVIEW_EAP_VIDEO_PLUS_MESSAGE,
} from '../../utils/reviewPlan';
import { MatchingLanguage } from '../../Helpers/apiService';

export type RoomType = 'privateRoom' | 'couplesRoom' | 'psychiatryRoom';

export enum PROVIDER_GENDERS {
  MALE = '1',
  FEMALE = '2',
  ANY = '3',
}

export const therapistGenderPreferenceDict = {
  [PROVIDER_GENDERS.MALE]: 'Male',
  [PROVIDER_GENDERS.FEMALE]: 'Female',
  [PROVIDER_GENDERS.ANY]: 'Any',
};

const clientGenderDict = {
  1: 'Male',
  2: 'Female',
  3: 'Transgender male',
  4: 'Transgender female',
  5: 'Gender queer',
  6: 'Gender variant',
  7: 'Other',
  8: 'Non-binary',
  9: 'Prefer not to share',
};

export enum PAYFIRST_ANALYTIC_IDS {
  FIELDS_OF_EXPERTISE = 54,
  PROVIDER_GENDER_PREFERENCE = 57,
  CLIENT_STATE = 63,
}

export type GenderType = keyof typeof clientGenderDict;

export type TherapistGenderPreference = keyof typeof therapistGenderPreferenceDict;

export interface AnswerParam {
  address?: string;
  address2?: string | undefined;
  addressStreet?: string;
  country?: string;
  dateOfBirth?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  state?: string;
  zipCode?: string;
}

export interface ChosenPlan {
  id: number;
  name: string;
  price: string;
  price_frequency: string;
  price_per_pay_frequency: string;
  price_per_week: string;
}

export interface Appointment {
  end: string;
  start: string;
}

interface TherapistLicenses {
  description?: string;
  license?: string;
  state?: string;
  type: string;
}

export interface AnswerValue {
  stepId: number;
  response_category_id: number;
  response_prompt: string;
  response_value: string | undefined;
  discard_answer?: boolean;
  appointment: Appointment;
  invalidVoucher: boolean;
  service: ServiceType;
  insuranceEligibility: InsuranceEligibility | undefined;
  insuranceConfirmation: InsuranceConfirmation;
  trizettoErrorStatusCode: number;
  params: AnswerParam;
  voucher: string;
  copayCents: string;
  insuranceCode: string;
  trizettoRequestId: string;
  livesInTheUS: boolean;
  go_to_matches: boolean;
  payfirst_step_id?: number;
  response_self_serve?: {
    field: string;
    value: string | number[];
  };
  quickStatusAction: QuickStatusAction;
}

export interface ChosenTherapist {
  id: number;
  gender?: TherapistGenderPreference;
  bio?: string;
  firstName: string;
  lastName: string;
  title: string;
  additionalLanguages?: Array<string>;
  timezone?: string;
  yearsExperience?: number;
  yearsWithTalkspace?: number;
  licenses: TherapistLicenses[];
  allFieldsOfExpertise?: Array<string>;
  expertise?: {
    mostRelevant: Array<string>;
    treatmentOrientations: Array<string>;
    additionalExperience: Array<string>;
  };
  businessDays?: {
    sunday: boolean;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
  };
}

export interface StepAnswer {
  flowId: number;
  stepId: number;
  questionId: string | number;
  answerValue?: AnswerValue;
  chosenTherapist?: ChosenTherapist;
  chosenPlan?: ChosenPlan;
}

type ResponseSelfServeValue = number;

export interface ResponseSelfServe {
  field: string;
  value: ResponseSelfServeValue[] | string;
}

export interface Response {
  flowId?: number;
  stepId?: number;
  go_to_matches?: boolean;
  payfirst_step_id?: number;
  payfirst_step_prompt?: string;
  response_category_id?: number;
  response_prompt?: string;
  response_value?: string;
  livesInTheUS?: boolean;
  response_self_serve?: ResponseSelfServe;
  isEligible?: boolean;
  eligibilityPromo?: number;
}

export interface Match {
  id: number;
  gender: TherapistGenderPreference;
  bio: string;
  firstName: string;
  lastName: string;
  title: string;
  additionalLanguages: string[];
  timezone: string;
  yearsExperience: number;
  yearsWithTalkspace: number | null;
  licenses: Array<{
    license: string;
    state: string;
    type: string;
    description: string;
  }>;
  allFieldsOfExpertise: string[];
  expertise: {
    mostRelevant?: string[];
    treatmentOrientations?: string[];
    additionalExperience?: string[];
  };
  businessDays: {
    sunday: boolean;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
  };
  implicitBusinessHours: ImplicitBusinessHoursByDay;
  // don't know if these should be dates or strings. Might be legacy code but referenced in MatchTab
  timeOffStart?: any;
  timeOffEnd?: any;
}

export type FiltersHomePageState = Pick<
  HomePageState,
  | 'clientState'
  | 'clientCountry'
  | 'livesInTheUS'
  | 'shouldResetLivingInfo'
  | 'clientMatchGenderPreference'
  | 'clientMatchPresentingProblems'
  | 'stepAnswers'
>;

export type B2BForkResult = 'b2b' | 'b2c';

export type FunnelVariation =
  | 'customer_selected'
  | 'psychiatry_quickmatch'
  | 'qm_ct_exit'
  | 'reactivation'
  | 'update-coverage'
  | 'mbh-ineligibility';

export type CurrentProvidersInfo = NonNullable<
  PromiseMessageQMInitDataReceiver['currentProvidersInfo']
>;
export type HasOnlyPlaceholderProviders = NonNullable<
  PromiseMessageQMInitDataReceiver['hasOnlyPlaceholderProviders']
>;

export interface InsuranceDetails {
  firstName?: string | null;
  lastName?: string | null;
  state?: string | null;
  dob?: string | null;
  gediPayerID?: string | null;
  payerName?: string | null;
  memberID?: string | null;
  dependentRelationship?: string | null;
  copayCents?: number | null;
  isEligible?: boolean;
  isVideoOnlyPlan?: boolean;
  verificationSucceeded?: boolean;
}

export interface HomePageState {
  stepAnswers: StepAnswer[];
  countries: [];
  responses: Response[];
  livesInTheUS: boolean;
  isGDPR: boolean | undefined;
  shouldResetLivingInfo: boolean;
  clientAgeTitle: undefined | string;
  clientAge: undefined | number | string;
  clientAgeNumber: undefined | number;
  /**
   * String date format: YYYY-MM-DD
   */
  clientDateOfBirth: string | undefined;
  clientGender: GenderType | undefined;
  clientMaritalStatus: undefined | string;
  clientLanguage: undefined | string;
  clientState: undefined | string;
  clientCountry: undefined | string;
  clientMatchExpertisePreference: undefined | string;
  maxResults: number;
  isNoMatches: boolean;
  answerParams?: AnswerParam;
  voucher: string;
  matches: Match[] | null;
  invalidVoucher: boolean;
  opacity: number;
  isMatchesOverlayDismissed: boolean;
  isFiltersModalVisible: boolean;
  qmPartnerCode: string;
  cpPartnerCode: string;
  copayCents: string | undefined;
  insuranceCode: string | null | undefined;
  redirectFrom: number;
  flowId: number;
  stepId: number;
  step: FlowStep | null;
  flowConfig: FlowConfig | undefined;
  exactMatch: boolean;
  chosenPlan: ChosenPlan | undefined;
  appointment: Appointment | undefined;
  therapistId: number;
  therapistFirstName: string;
  therapistLastName: string;
  therapistTitle: string;
  therapistLicenses: TherapistLicenses[] | null;
  currentProvidersInfo: CurrentProvidersInfo | null;
  hasOnlyPlaceholderProviders: HasOnlyPlaceholderProviders | null;
  clientMatchPresentingProblems: ClientMatchPresentingProblem[];
  clientMatchGenderPreference: undefined | string;
  clientMatchEthnicityPreference: undefined | string;
  isDisabledPreviousButton: boolean | undefined;
  funnelVariation: FunnelVariation | null;
  service: ServiceType | null;
  currentTier: number;
  trizettoErrorStatusCode: number;
  isCouldNotBeVerified: boolean;
  trizettoRequest: number | string | null | undefined;
  coupon: null | string;
  couponApplied: boolean;
  restoringSession: boolean;
  keepProvider: boolean | undefined;
  skipCapacityCheck?: boolean | undefined;
  isHideTeenServiceType: boolean;
  isSkipDirectToBH: boolean;
  isHideNonBhItems: boolean;
  isCreateRoomFlow: boolean;
  isReactivationFlow: boolean;
  isUpdateCoverageFlow: boolean;
  isMBHIneligibilityFlow: boolean;
  roomID: null | number;
  existingUserEmail: string;
  insuranceEligibility: InsuranceEligibility | undefined;
  isQMFlowDone: boolean;
  isPsychiatryRoom: boolean;
  redirectFromFlowConfig: FlowConfig | undefined;
  loadingMatches: boolean;
  roomType?: RoomType;
  shouldBlurContent?: boolean;
  isConfirmScheduler?: boolean;
  isVideoOnlyPlan: boolean;
  /** You were verified through your organization's email or reserver keyword and you got a flowID for eligibility question settings */
  organizationFlowID: number | undefined;
  b2bForkResult: B2BForkResult | undefined;
  insurancePayer: InsuranceEligibilityPayer | undefined;
  /** Flow ID mapped to a particular payer. Set by the B2BFork */
  partnerFlowID: number | undefined;
  isPayerOutage: boolean | undefined;
  isB2BOutOfNetwork: boolean | undefined;
  experimentsVariants: ExperimentsVariants | undefined;
  collectReferralSourceOnSignUp: boolean | undefined;
  referralSource: string;
  allowedModalities: Array<AllowedModality> | undefined;
  totalSessions: number;
  /** Array of stepIDs to skip */
  stepsToSkip: number[];
  hideVWOBar: boolean;
  reviewPlanDetails?:
    | typeof PLAN_REVIEW_BH
    | typeof PLAN_REVIEW_EAP_MESSAGE
    | typeof PLAN_REVIEW_EAP_VIDEO_PLUS_MESSAGE;
  accountType: AccountType | null;
  manualFlowID: number | undefined;
  updateCoverageRoomID: number | undefined;
  oldRoomHasSessions: boolean;
  sessionsTransferred: boolean | undefined;
  sessionsCanceled: boolean | undefined;
  quickEligibilityInfo: QuickEligibilityInfo | null;
  memberAvailability: MemberAvailability | undefined;
  eligibilityFiles: EligibilityFiles | null;
  manualVerificationEmail: string;
  previousTherapistId: number | null;
  matchingLanguages: MatchingLanguage[] | null;
  overriddenBackButtonBehavior: (() => void) | undefined;
  remoteFlowConfigs: Record<number, FlowConfig> | undefined;
  isRegistrationError: null | boolean;
  therapistIDFromCreateRoom: number | undefined;
  insuranceDetails: InsuranceDetails;
  skipCreditCard?: boolean;
}

export interface HomePageActions {
  setHomePageState: (state: Partial<HomePageState>) => void;
  setOverriddenBackButtonBehavior: (arg: (() => void) | undefined) => void;
  getFlow: (flowID: number, includeDeleted?: boolean) => QMFlow | undefined;
  getFlowConfig: (flowID?: number, includeDeleted?: boolean) => FlowConfig | undefined;
  getMaxCost: (flowID?: number, service?: ServiceType | null) => number;
  getMaxCostByFlowConfig: (
    flowConfig: FlowConfig | undefined,
    service?: ServiceType | null
  ) => number;
  setIsRegistrationError: (isError: boolean) => void;
  setIsPayerOutage: (isPayerOutage: boolean) => void;
}

export type DynamicValueHomePageState = Pick<
  HomePageState,
  | 'stepAnswers'
  | 'clientState'
  | 'insurancePayer'
  | 'qmPartnerCode'
  | 'cpPartnerCode'
  | 'b2bForkResult'
  | 'matchingLanguages'
  | 'responses'
>;

export interface DynamicValueTranslators {
  tQuickmatchCommon: TFQuickmatchCommon;
}

export type MapSelfServeFieldsState = Pick<
  HomePageState,
  'stepAnswers' | 'responses' | 'clientState'
>;

export const recoveredStateFromHomePageState = [
  'livesInTheUS',
  'isMatchesOverlayDismissed',
  'allowedModalities',
  'totalSessions',
  'accountType',
  'responses',
  'skipCreditCard',
] as const;

export type RecoveredStateFromHomePageState = Record<
  typeof recoveredStateFromHomePageState[number],
  HomePageState[typeof recoveredStateFromHomePageState[number]] | undefined
>;

// TODO: @LuisRizo  with ts-frontend version. Make that version an enum
export enum AccountType {
  EAP = 'eap',
  BH = 'bh',
  DTE = 'dte',
  B2C = 'b2c',
  SMB = 'smb',
  'CHANNEL/BROKER/TPA' = 'channel/broker/tpa',
  EDU = 'edu',
}

export function isAccountType(accountType: string): accountType is AccountType {
  return Object.values(AccountType).includes(accountType as AccountType);
}

export type RunSkipQuestionActions = (
  skipQuestionActions: Array<NextQuestionAction> | NextQuestionActionForTarget | undefined,
  currentQuestionId?: string | number | null | undefined,
  answer?
) => (number | undefined | null | string) | Promise<number | undefined | null | string>;

export type QuickEligibilityInfo = {
  firstName?: string | null;
  lastName?: string | null;
  memberID?: string | null;
  groupID?: string | null;
  dob?: string | null;
  state?: string | null;
  country?: string | null;
  payerID?: number | null;
  insuranceEligibility?: {
    copayCents?: number;
    insuranceCode?: string;
    isEligible: boolean;
    isVideoOnlyPlan: boolean;
    trizettoRequestId?: number;
    verificationSucceeded: boolean;
    gediPayerID?: string;
    payerName?: string;
    memberID?: string;
    dependentRelationship?: string;
  };
  insurancePayer?: InsuranceEligibilityPayer | null;
};

export type MemberAvailability = Array<MemberAvailabilityItem>;
export type EligibilityFiles = {
  insuranceFront: File;
  insuranceBack: File;
  idFront: File;
  idBack?: File;
};

export interface PlanInfo {
  insurancePayerID?: number;
  voucherCode?: string;
  planGroup?: number;
}

export type DayOfWeek =
  | 'Monday'
  | 'Tuesday'
  | 'Wednesday'
  | 'Thursday'
  | 'Friday'
  | 'Saturday'
  | 'Sunday';

export interface MemberAvailabilityItem {
  dayOfWeek: DayOfWeek;
  availability: Array<{
    startTime: string;
    endTime: string;
  }>;
  alwaysAvailable: boolean;
}
