import { useReducer, useCallback, createContext, useContext, useEffect, useRef } from 'react';
import * as React from 'react';
import moment from 'moment';
import { trackWizardEvent, trackWizardExitEvent } from '@/utils/analytics/events';
import StepWizardApiHelper from '../utils/StepWizardApiHelper';
import { AdditionalMetaData, BaseWizardState } from '../reducers/wizardState';
import { reorderResponseSteps } from '../utils/wizardUtils';

type WizardAction<D extends BaseWizardState> =
  | {
      type: 'requestInitialState';
    }
  | {
      type: 'setState';
      payload: Partial<D>;
    }
  | { type: 'setIsError' }
  | { type: 'openVerifyExitModal'; payload: Partial<D> }
  | { type: 'closeVerifyExitModal' }
  | { type: 'exitOnVerifyExitModal' };

export const wizardReducer = <T extends BaseWizardState>(state: T, action: WizardAction<T>) => {
  switch (action.type) {
    case 'requestInitialState':
      return {
        ...state,
        isLoading: true,
      };
    case 'setState':
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    case 'setIsError':
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case 'openVerifyExitModal':
      return {
        ...state,
        isLoading: false,
        isError: false,
        isVerifyExitModalOpen: true,
        ...action.payload,
      };
    case 'closeVerifyExitModal':
      return {
        ...state,
        isLoading: false,
        isError: false,
        isVerifyExitModalOpen: false,
      };
    case 'exitOnVerifyExitModal':
      return {
        ...state,
        isLoading: true,
        isVerifyExitModalOpen: false,
      };
    default:
      return state;
  }
};

export interface WizardGenericActions<
  T extends BaseWizardState,
  R extends StepWizardApiHelper = StepWizardApiHelper
> {
  prePersist?: (state: T, wizardCompleted: boolean) => Promise<void>;
  persist?: (api: R, state: Partial<T>, wizardCompleted: boolean) => Promise<void>;
  initState: (api: R, state: T) => Promise<Partial<T>>;
  closeWizardAction: (
    roomID?: number,
    shouldNavigateToRoom?: boolean,
    additionalMetaData?: AdditionalMetaData
  ) => void;
}

interface SetStepResponseOptions<T> {
  selectedValue: string;
  additionalData: Record<string, unknown>;
  stepName: string;
  stateUpdate: Partial<T>;
}

interface WizardActions<T = object> {
  setState: (partialState: Partial<T>) => void;
  persist: (wizardCompleted: boolean, roomID?: number) => Promise<void>;
  // TODO: @Luis - Fix the `any` in the (hopefully near) future
  closeWizard: WizardGenericActions<any>['closeWizardAction'];
  onExitClick: () => void;
  setStepValue: (value: any) => void;
  setDefaultStepValue: <ValueT>(value: ValueT) => void;
  setStepResponse: (options: SetStepResponseOptions<T>) => void;
  setResponsesManualOrder: (stepNames: string[]) => void;
}

const WizardStateContext = createContext<any | undefined>(undefined);

const WizardActionsContext = createContext<WizardActions | undefined>(undefined);

export const StepWizardProvider = <
  T extends BaseWizardState,
  R extends StepWizardApiHelper = StepWizardApiHelper
>({
  children,
  initialState,
  genericActions,
  apiHelperInstance,
  ApiHelperOverride,
}: React.PropsWithChildren<{
  apiHelperInstance?: R;
  ApiHelperOverride?: { new (): R };
  initialState: T;
  genericActions: WizardGenericActions<T, R>;
}>) => {
  const [state, dispatch] = useReducer<(prevState: T, action: WizardAction<T>) => T>(
    wizardReducer,
    initialState
  );
  const ApiHelper = ApiHelperOverride || StepWizardApiHelper;
  const api = useRef(apiHelperInstance || (new ApiHelper() as R));

  const setState = useCallback(
    (partialState: Partial<T>) => {
      dispatch({
        type: 'setState',
        payload: partialState,
      });
    },
    [dispatch]
  );

  const persist = useCallback(
    async (wizardCompleted: boolean = false, roomID?: number) => {
      if (state.disablePersist || state.persisted) return;
      setState({ isLoading: true } as unknown as Partial<T>);
      const stepsResult = {
        roomID: roomID || state.roomID,
        wizardName: state.wizardType,
        wizardVersion: state.wizardVersion,
        response: state.responses,
      };
      if (state.responsesManualOrder) {
        stepsResult.response.steps = reorderResponseSteps(
          state.responses.steps,
          state.responsesManualOrder
        );
      }

      stepsResult.response.contextID = state.contextID;
      stepsResult.response.source = state.source;
      stepsResult.response.completed = wizardCompleted;
      try {
        if (genericActions.prePersist)
          await genericActions.prePersist(state, wizardCompleted).catch(() => {
            dispatch({ type: 'setIsError' });
          });
        await api.current
          .saveWizardStepsResults(state.clientUserID || 0, stepsResult)
          .catch(api.current.dismissIfCancelled);
        if (genericActions.persist) {
          await genericActions.persist(api.current, state, wizardCompleted).catch(() => {
            dispatch({ type: 'setIsError' });
          });
        }
        setState({ persisted: true, isLoading: true } as Partial<T>);
      } catch (err) {
        dispatch({ type: 'setIsError' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [genericActions, state]
  );

  const onExitClick = async () => {
    dispatch({
      type: 'exitOnVerifyExitModal',
    });
    trackWizardExitEvent(state.eventCategory, state.clientUserID);
    await persist(false, state.roomID);
    genericActions?.closeWizardAction && genericActions.closeWizardAction(state.roomID);
  };

  const setStepValue = (value: any) => {
    if (state.setStepValueCallback) {
      state.setStepValueCallback(value);
    } else {
      // eslint-disable-next-line no-console
      console.warn('Step value callback is not set. Ignoring');
    }
  };
  const setDefaultStepValue = (value: any) => {
    setState({ currentDefaultStepValue: value } as Partial<T>);
  };

  useEffect(() => {
    if (state.shouldPersist) persist(state.isSuccess || true);
  }, [state.shouldPersist, state.isSuccess]); // eslint-disable-line

  const closeWizard: typeof genericActions.closeWizardAction = (...args) => {
    if (genericActions.closeWizardAction) {
      genericActions.closeWizardAction(...args);
    }
  };

  const setStepResponse = ({
    selectedValue,
    additionalData,
    stepName,
    stateUpdate,
  }: SetStepResponseOptions<T>) => {
    trackWizardEvent('Wizard Step Answer', state.eventCategory || '', {
      'User ID': state.clientUserID,
      label: stepName,
      Application: state.eventCategory,
      eventProperty: selectedValue,
      eventPropertyValue: 0.0,
      Question: stepName,
      Response: selectedValue || undefined,
      'Step ID': state.currentStepNumber,
    });
    const currentStepResponses = state.responses;
    currentStepResponses.lastStepName = stepName;
    const response = {
      name: stepName,
      additionalData,
      value: selectedValue,
      skip: false,
      restored: false,
      doAction: false,
      createdAt: moment().format('MMM DD, YYYY HH:mm:ss'),
    };
    currentStepResponses.steps.push(response);
    setState({
      responses: currentStepResponses,
      ...stateUpdate,
    });
  };

  const setResponsesManualOrder = (stepNames: string[] | undefined) => {
    setState({
      responsesManualOrder: stepNames,
    } as Partial<T>);
  };

  const actions: WizardActions<T> = {
    setState: useCallback(setState, [setState]),
    persist,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    closeWizard: useCallback(closeWizard, [genericActions.closeWizardAction]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    onExitClick: useCallback(onExitClick, [genericActions.closeWizardAction, state, persist]),
    setStepValue: useCallback(setStepValue, [state]),
    setDefaultStepValue: useCallback(setDefaultStepValue, [setState]),
    setStepResponse: useCallback(setStepResponse, [state, setState]),
    setResponsesManualOrder: useCallback(setResponsesManualOrder, [setState]),
  };

  useEffect(() => {
    dispatch({ type: 'requestInitialState' });
    const loader = () => {
      genericActions
        .initState(api.current, state)
        .then((partial: Partial<T>) => {
          setState({ ...partial, isRestoringAnswers: true });
        })
        .catch(() => dispatch({ type: 'setIsError' }));
    };
    loader();
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      api.current.cancelAll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WizardStateContext.Provider value={state}>
      <WizardActionsContext.Provider value={actions}>{children}</WizardActionsContext.Provider>
    </WizardStateContext.Provider>
  );
};

export function useWizardState<T>() {
  const context = useContext(WizardStateContext) as T;
  if (context === undefined)
    throw new Error('useWizardState must be used within a ContextProvider');
  return context;
}

export function useWizardActions<T>() {
  const context = useContext(WizardActionsContext) as WizardActions<T>;
  if (context === undefined)
    throw new Error('useWizardActions must be used within a ContextProvider');
  return context;
}
