import { FunctionComponent } from 'react';
import View from '../View';
import Big from '../Typography/Big';
import TSLogo from '../TSLogo';
import ScrollView from '../ScrollView';
import PanelHeader from '../PanelHeader';
import ResponsiveLayoutWithHeader, { SafeAreaVariant } from '../ResponsiveLayoutWithHeader';
import BackNextButton from '../BackNextButton';
import HeaderBackWrapper from '../HeaderBackWrapper';
import ContentBackWrapper from '../ContentBackWrapper';
import { useWindowWidthState } from '../../hooks/windowWidthContext';
import { ScreenProvider, useScreenState } from '../../hooks/useScreen';

import { EmotionStyle, useEmotionTheme } from '../../core/styled';

export interface ScreenProps {
  showBackButton?: boolean;
  showCloseButton?: boolean;
  shouldNotFocus?: boolean;
  title?: string;
  titleId?: string;
  onScrollChange?: (scrollTop: number) => void;
  onCloseButtonClick?: () => void;
  onBackButtonClick?: () => void;
  closeButtonDataQa?: string;
  backButtonDataQa?: string;
  containerDataQa?: string;
  backButtonStyles?: EmotionStyle;
  scrollViewStyles?: EmotionStyle;
  additionalHeaderTopPadding?: number;
  safeAreaVariant?: SafeAreaVariant;
  contentWrapperStyle?: EmotionStyle;
  renderHeader?: (() => JSX.Element) | null;
  renderHeaderRight?: (() => JSX.Element) | null;
  renderHeaderLeft?: (() => JSX.Element) | null;
  isNewNav?: boolean;
}

const Screen: FunctionComponent<ScreenProps> = ({
  children,
  onScrollChange,
  showBackButton = false,
  showCloseButton = true,
  shouldNotFocus = false,
  title,
  titleId,
  onCloseButtonClick,
  onBackButtonClick,
  closeButtonDataQa,
  backButtonDataQa,
  containerDataQa,
  backButtonStyles,
  scrollViewStyles,
  additionalHeaderTopPadding = 0,
  safeAreaVariant,
  contentWrapperStyle,
  renderHeader,
  renderHeaderRight,
  renderHeaderLeft,
}) => {
  const { isMobile } = useWindowWidthState();
  const { backButtonCallback, isNewLogo } = useScreenState();

  const handleCloseButtonClick = () => {
    if (onCloseButtonClick) {
      onCloseButtonClick();
    }
  };

  const { colors } = useEmotionTheme();

  return (
    <ResponsiveLayoutWithHeader
      safeAreaVariant={safeAreaVariant}
      contentWrapperStyle={contentWrapperStyle}
      headerHeight={67 + additionalHeaderTopPadding}
      shouldScroll={false}
      shouldNotFocus={shouldNotFocus}
      dataQa={containerDataQa}
      titleId={titleId}
      renderHeader={
        renderHeader ||
        (() => (
          <PanelHeader
            isNewNav
            renderLeft={() => (
              <>
                {isMobile && (
                  <View style={{ width: 42 }}>
                    {(showBackButton || backButtonCallback) && (
                      <HeaderBackWrapper>
                        <BackNextButton
                          onPress={backButtonCallback || onBackButtonClick}
                          style={{
                            display: 'inline-block',
                            borderRadius: 5,
                            marginBottom: 2,
                            ...(backButtonStyles && { ...backButtonStyles }),
                          }}
                          roundedFocusStyle
                          primaryColor={colors.permaTalkspaceDarkGreen}
                          caretColor={colors.permaTalkspaceDarkGreen}
                          dataQa={backButtonDataQa}
                        />
                      </HeaderBackWrapper>
                    )}
                  </View>
                )}
                {renderHeaderLeft && renderHeaderLeft()}
                <View flex={1} align={isMobile ? 'center' : 'start'}>
                  {title ? <Big>{title}</Big> : <TSLogo variant={isNewLogo ? '2024' : 'old'} />}
                </View>
              </>
            )}
            renderRight={renderHeaderRight}
            onRightPress={showCloseButton ? () => handleCloseButtonClick() : undefined}
            dataQa={closeButtonDataQa}
            style={{ paddingTop: additionalHeaderTopPadding }}
          />
        ))
      }
    >
      <ScrollView
        onScrollChange={onScrollChange}
        style={{
          padding: !isMobile ? 22 : 0,
          overflowY: 'overlay',
          overflowX: 'hidden',
          ...scrollViewStyles,
        }}
      >
        {showBackButton && (
          <ContentBackWrapper>
            <BackNextButton
              onPress={onBackButtonClick}
              style={{
                display: 'inline-block',
                borderRadius: 5,
                marginBottom: 2,
                ...(backButtonStyles && { ...backButtonStyles }),
              }}
              roundedFocusStyle
              primaryColor={colors.green}
            />
          </ContentBackWrapper>
        )}
        {children}
      </ScrollView>
    </ResponsiveLayoutWithHeader>
  );
};

const WrappedScreen: typeof Screen = ({ children, ...props }) => (
  <ScreenProvider>
    <Screen {...props}>{children}</Screen>
  </ScreenProvider>
);

export default WrappedScreen;
