import { BannerCardProps, BannerCardSize, BannerCardVariant } from './types';
import { spacing } from '../../tokens';
import styled from '../../../core/styled';
import View from '../../../components/View';
import { ColorRolesVersion } from '../../types';
import { Standard } from '../../../index.next';
import { ExclamationCircle, TouchableView } from '../../../components';

const { space150, space200, space100 } = spacing;

const getBackgroundColor = (colorRoles: ColorRolesVersion, variant?: BannerCardVariant) => {
  switch (variant) {
    case 'success':
      return colorRoles.surfaces.successSubtleDefault;
    case 'warning':
      return colorRoles.surfaces.warningSubtleDefault;
    case 'critical':
      return colorRoles.surfaces.criticalSubtleDefault;
    default:
      return colorRoles.surfaces.infoSubtle;
  }
};

const getBannerSize = (size?: BannerCardSize) => {
  switch (size) {
    case 'medium':
    case 'warning':
      return {
        maxWidth: 624,
      };
    case 'large':
      return {
        maxWidth: 1008,
      };
    default:
      return {
        maxWidth: 343,
      };
  }
};

const BannerCardContainer = styled(View)<{
  variant?: BannerCardVariant;
  size?: BannerCardSize;
}>(({ variant, size, theme: { colorRoles } }) => {
  const { maxWidth } = getBannerSize(size);

  return {
    flex: 1,
    position: 'relative',
    padding: space200,
    borderRadius: space150,
    background: getBackgroundColor(colorRoles, variant),
    maxWidth,
    height: 'max-content',
  };
});

const Title = styled(Standard)(({ theme: { colorRoles } }) => {
  return {
    fontSize: 16,
    fontWeight: 600,
    color: colorRoles.typography.textDefault,
  };
});

const Subtitle = styled(Standard)(({ theme: { colorRoles } }) => {
  return {
    fontSize: 14,
    fontWeight: 400,
    color: colorRoles.typography.textSubtle,
    paddingRight: space200,
  };
});

const SecondaryButton = styled(TouchableView)(({ theme: { colors } }) => {
  return {
    padding: '6px 16px',
    borderRadius: space100,
    border: `1px solid ${colors.accessibilityGreenDark}`,
    color: colors.accessibilityGreenDark,
    minWidth: 104,
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 600,
  };
});

const PrimaryButton = styled(SecondaryButton)(({ theme: { colors } }) => {
  return {
    background: colors.accessibilityGreenDark,
    color: colors.white,
  };
});

const BannerCard = ({
  title,
  subtitle,
  variant = 'info',
  size = 'small',
  primaryButtonAction,
  primaryButtonTitle,
  secondaryButtonAction,
  secondaryButtonTitle,
  icon = <ExclamationCircle />,
  isMobile,
  fullWidth,
  style,
}: BannerCardProps) => (
  <BannerCardContainer
    row={!isMobile && size !== 'small'}
    variant={variant}
    size={size}
    style={style}
  >
    {isMobile || size === 'small' ? (
      <View row>
        <View>{icon}</View>
        <View flex={1} wrap="wrap" style={{ marginLeft: 8, paddingRight: space200 }}>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </View>
      </View>
    ) : (
      <>
        <View>{icon}</View>
        <View
          flex={1}
          wrap="wrap"
          style={{
            marginLeft: 8,
            paddingRight: space200,
            maxWidth: (primaryButtonTitle || secondaryButtonTitle) && !fullWidth ? 289 : undefined,
          }}
        >
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </View>
      </>
    )}
    <View
      row
      style={{
        gap: space100,
        marginTop: space150,
        alignSelf: 'end',
        right: isMobile || size === 'small' ? 0 : 250,
      }}
    >
      {secondaryButtonTitle && (
        <SecondaryButton onPress={() => secondaryButtonAction && secondaryButtonAction()}>
          {secondaryButtonTitle}
        </SecondaryButton>
      )}
      {primaryButtonTitle && (
        <PrimaryButton onPress={() => primaryButtonAction && primaryButtonAction()}>
          {primaryButtonTitle}
        </PrimaryButton>
      )}
    </View>
  </BannerCardContainer>
);

export default BannerCard;
