import AnimatedT from './AnimatedT';

import { withVersioning } from '../../utils';
import { VersionMap } from '../../types';

const versionMap: VersionMap<React.ComponentProps<typeof AnimatedT>> = {
  DefaultComponent: AnimatedT,
};

export default withVersioning(versionMap);
