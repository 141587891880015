import Link from '../Link';
import TextDS from '../../designSystems/components/typography/TextDS';

type PolicyLinkType =
  | 'termsOfUse'
  | 'telemedicineConsent'
  | 'privacyPolicy'
  | 'teenNYCPrivacyPolicy';

const linkMapping: { [key in PolicyLinkType]: { text: string; href: string; dataQa: string } } = {
  termsOfUse: {
    text: 'Terms of use',
    href: 'https://www.talkspace.com/public/terms',
    dataQa: 'termsOfUseLink',
  },
  telemedicineConsent: {
    text: 'Telemedicine',
    href: 'https://www.talkspace.com/public/telemedicine',
    dataQa: 'telemedicineConsentLink',
  },
  privacyPolicy: {
    text: 'Privacy Policy',
    href: 'https://www.talkspace.com/public/privacy-policy',
    dataQa: 'privacyPolicyLink',
  },
  teenNYCPrivacyPolicy: {
    text: 'NYC Teenspace Privacy Policy',
    href: 'https://www.talkspace.com/public/nyc-teenspace-privacy-policy',
    dataQa: 'teenNYCPrivacyPolicyLink',
  },
};

interface Props {
  type: PolicyLinkType;
  text?: string;
}

const PolicyLink = ({ type, text }: Props) => {
  const { text: defaultText, href, dataQa } = linkMapping[type];
  return (
    <Link dataQa={dataQa} href={href} target="_blank" roundedFocusStyle>
      <TextDS inline variant="linkXs" colorRole="textInteractiveDefault">
        {text || defaultText}
      </TextDS>
    </Link>
  );
};

export default PolicyLink;
