import { useQuery } from 'react-query';
import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';
import { DYNAMIC_INTEGRATIONS_CONFIG } from '../types';

export const fetchDynamicIntegrationsConfig = () => async (): Promise<any> => {
  const response = await apiWrapper.get(
    `${apiHelper().publicAPIEndpoint}/public/v1/get-dynamic-integrations-config`
  );
  return response;
};

export const useDynamicIntegrationsConfig = () =>
  useQuery({
    queryKey: DYNAMIC_INTEGRATIONS_CONFIG,
    queryFn: fetchDynamicIntegrationsConfig(),
    staleTime: Infinity,
  });
