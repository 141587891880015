import { toast as _toast, Slide, ToastOptions } from 'react-toastify';
import { ColorRolesVersion } from '../designSystems/types';
import { Check, Sparkle } from '../designSystems/icons';
import { useEmotionTheme } from '../core/styled';
import { View, TextDS } from '../components';

type ToastType = 'default' | 'success';

const defaultToastConfig = {
  position: 'top-center',
  autoClose: 2000,
  hideProgressBar: true,
  draggable: true,
  transition: Slide,
  closeButton: false,
  // TODO: find a better way to conditionally override toast styles
  className: 'ts-toast',
  bodyClassName: 'ts-toast-body',
} as Partial<ToastOptions>;

const getToastTypeMapping = (colorRoles: ColorRolesVersion) => {
  return {
    default: {
      icon: <Sparkle colorType="inverse" />,
      color: colorRoles.surfaces.neutralBoldDefault,
    },
    success: {
      icon: <Check colorType="inverse" />,
      color: colorRoles.surfaces.successBoldDefault,
    },
  };
};

const ToastContent = ({ type, text }: { type: ToastType; text: string }) => {
  const { spacing, colorRoles } = useEmotionTheme();
  const toastType = getToastTypeMapping(colorRoles)[type];

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: toastType.color,
          padding: `${spacing('space200')}px ${spacing('space300')}px`,
          borderRadius: spacing('space150'),
        }}
      >
        {toastType.icon}
        <TextDS
          variant="bodySm"
          colorRole="textInverse"
          style={{ marginLeft: spacing('space100'), whiteSpace: 'nowrap' }}
        >
          {text}
        </TextDS>
      </View>
    </>
  );
};

/**
 * Custom hook to render DS Toast
 */
const useToastify = (): {
  toast: (text: string, type?: ToastType, options?: ToastOptions) => void;
} => {
  return {
    toast: (text, type = 'default', options = {}) => {
      const props = { text, type };

      _toast(<ToastContent {...props} />, {
        ...defaultToastConfig,
        ...options,
      } as ToastOptions);
    },
  };
};

export default useToastify;
