import { forwardRef } from 'react';
import styled, { useEmotionTheme, EmotionStyle } from '../../../core/styled';
import { webOnlyStyle } from '../../../core/styleHelpers';
import BaseButton, { BaseButtonProps } from './BaseButton';
import Spinner from '../../../components/Spinner';
import { useShareForwardedRef } from '../../../hooks/a11yHelper';

export type ButtonSize = 'small' | 'medium' | 'large';
export interface ButtonIconProps {
  color?: string;
  style?: EmotionStyle;
}
export type SVGTarget = 'g' | 'path';
export type FillTarget = 'fill' | 'stroke';
export type SVGConfig = {
  [K in SVGTarget]?: FillTarget[];
};

export interface ButtonProps extends BaseButtonProps {
  text?: string | Element;
  isSecondary?: boolean;
  stretch?: boolean;
  size?: ButtonSize;
  style?: EmotionStyle;
  isLoading?: boolean;
  removeBorder?: boolean;
  dataQa?: string;
  variant?: 'primary' | 'secondary' | 'tertiary';
  hasCaretDown?: boolean;
  Icon?: React.ComponentType<ButtonIconProps>;
  isActive?: boolean;
  mode?: SVGConfig;
  disableSubmit?: boolean;
}

const ButtonComponent = styled(BaseButton)<Partial<ButtonProps>>(
  ({
    disabled,
    isSecondary,
    style = {},
    size = 'medium' as ButtonSize,
    theme: { colors, isHighContrast },
    stretch,
    removeBorder,
    primaryColor,
  }) => {
    const backgroundColor = primaryColor || colors.green;
    const stylesBySize: { [k: string]: EmotionStyle } = {
      small: {
        fontSize: 14,
        fontWeight: '400',
        fontFamily: "'Roboto', sans-serif",
        padding: 4,
        paddingLeft: 14,
        paddingRight: 14,
        display: 'flex',
        borderRadius: 6,
        border: isSecondary ? `1px solid ${colors.ironGrey}` : 'none',
        opacity: disabled && !isHighContrast ? 0.6 : 1,
      },
      medium: {
        backgroundColor: disabled ? colors.extraLightGrey : backgroundColor,
        height: 50,
        minHeight: 50,
        width: stretch ? '100%' : 300,
        fontWeight: '700',
        fontFamily: "'Roboto', sans-serif",
        borderRadius: 10,
        alignItems: 'center',
        padding: '0 5px',
        justifyContent: 'center',
        color: colors.white,
        fontSize: 17,
        marginTop: 12,
      },
      large: {
        backgroundColor: disabled ? colors.extraLightGrey : backgroundColor,
        height: 50,
        minHeight: 50,
        width: stretch ? '100%' : 300,
        fontWeight: '700',
        fontFamily: "'Roboto', sans-serif",
        borderRadius: 10,
        alignItems: 'center',
        padding: '0 20px',
        justifyContent: 'center',
        color: colors.white,
        fontSize: 17,
        marginTop: 12,
      },
    };
    return {
      color: isSecondary ? colors.green : colors.white,
      backgroundColor: isSecondary ? colors.white : backgroundColor,
      ...(stylesBySize[size] || stylesBySize.medium),
      ...(removeBorder ? { border: 'none' } : {}),
      ...style,
    };
  }
);

const Button = forwardRef<
  HTMLButtonElement,
  ButtonProps & React.ComponentPropsWithoutRef<typeof ButtonComponent>
>((props, ref) => {
  const {
    onPress,
    text,
    children,
    style,
    isLoading = false,
    disabled = false,
    size,
    isSecondary = false,
    removeBorder = false,
    dataQa,
    disableSubmit = false,
    ...otherProps
  } = props;
  const buttonRef = useShareForwardedRef(ref);
  const { colors, isHighContrast } = useEmotionTheme();
  return (
    <ButtonComponent
      ref={buttonRef}
      data-qa={dataQa}
      onPress={disabled ? () => null : onPress}
      disabled={disabled}
      style={style}
      size={size}
      isSecondary={isSecondary}
      removeBorder={removeBorder}
      // need to set type='button' to disable the button submitting the form
      // https://stackoverflow.com/questions/932653/how-to-prevent-buttons-from-submitting-forms/10836076#10836076
      type={disableSubmit ? 'button' : undefined}
      {...otherProps}
    >
      {(isLoading && (
        <Spinner
          containerStyle={{
            ...webOnlyStyle({
              transform: size === 'small' ? 'scale(0.5)' : 'scale(0.7)',
            }),
            opacity: size === 'small' && disabled && !isHighContrast ? 0.6 : 1,
          }}
          thickness={4}
          secondaryColor={isSecondary ? colors.white : colors.green}
          primaryColor={size === 'small' && isSecondary ? colors.ironGrey : colors.white}
          isLoading
        />
      )) ||
        text ||
        children}
    </ButtonComponent>
  );
});

export default Button;
