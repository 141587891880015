import { TextDS, View, spacing, useEmotionTheme, EmotionStyle } from '../../../index';
import { getTextColorForSurface } from '../../styles/textColor';
import { ColorRolesVersion } from '../../types';

const { space025, space100 } = spacing;

export type TagColorName = Extract<
  keyof ColorRolesVersion['surfaces'],
  | 'brandDefault'
  | 'successDefault'
  | 'warningDefault'
  | 'criticalBoldDefault'
  | 'infoDefault'
  | 'successBoldDefault'
  | 'decorativePsych'
  | 'decorativeCouples'
  | 'decorativeNeutral400'
  | 'decorativeGratitudeGreen'
  | 'criticalDefault'
>;

export const TagColorNames: TagColorName[] = [
  'brandDefault',
  'successDefault',
  'warningDefault',
  'criticalBoldDefault',
  'infoDefault',
  'successBoldDefault',
  'decorativePsych',
  'decorativeCouples',
  'decorativeNeutral400',
  'decorativeGratitudeGreen',
];

export interface TagProps {
  color: TagColorName;
  text: string;
  style?: EmotionStyle;
  inline?: boolean;
}
const Tag = ({ color, text, style, inline }: TagProps) => {
  const { colorRoles } = useEmotionTheme();
  const textColorRole = getTextColorForSurface(color);
  return (
    <View
      as={inline ? 'span' : undefined}
      style={{
        width: 'fit-content',
        borderRadius: 12,
        padding: `${space025}px ${space100}px`,
        backgroundColor: colorRoles.surfaces[color],
        display: inline ? 'inline' : 'flex',
        ...style,
      }}
    >
      <TextDS variant="bodyXs" colorRole={textColorRole} as={inline ? 'span' : undefined}>
        {text}
      </TextDS>
    </View>
  );
};

export default Tag;
