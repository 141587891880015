import referralOptions from './constants/referralOptions';

export interface IntegrationsGlobalConfig {
  preRegisterIntegrations: string[];
  maxBulkForTimeslotActiveCaching: number;
  timeslotCachingTtlInMinutes: number;
}

export interface DynamicIntegrationsConfigItem {
  name: string;
  referralSourceLabel: string;
  referralSourceValue: string;
}

interface ReferralOption {
  key: string;
  value: string;
  label: string;
}

function removeDuplicates(result: ReferralOption[]) {
  return result.reduce((acc: ReferralOption[], current: ReferralOption) => {
    const x = acc.find((item: ReferralOption) => item.value === current?.value);
    if (!x) {
      return acc.concat([current]);
    }
    return acc;
  }, [] as typeof result);
}

const getCombinedReferralOptions = (
  dynamicIntegrationsConfigItems: DynamicIntegrationsConfigItem[]
) => {
  if (!dynamicIntegrationsConfigItems) {
    return referralOptions;
  }

  const integrationReferralOptions = dynamicIntegrationsConfigItems.map((item) => {
    const label = item.referralSourceLabel;
    const value = item.referralSourceValue;
    return {
      key: 'referralSource',
      value,
      label,
    };
  });

  const referralOptionsWithoutOther = referralOptions.filter(
    (option: ReferralOption) => option.value !== 'other'
  );
  const other = referralOptions.filter((option) => option.value === 'other');

  const result: ReferralOption[] = [
    ...referralOptionsWithoutOther,
    ...integrationReferralOptions,
    ...other,
  ];

  const uniqueResult = removeDuplicates(result);

  return uniqueResult;
};

export default getCombinedReferralOptions;
