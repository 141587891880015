export type Environment =
  | 'local'
  | 'localSSL'
  | 'localTest'
  | 'mime'
  | 'test'
  | 'testBeta'
  | 'canary'
  | 'prod';

// Change to your IP address if doing LAN
const SSLHostname = 'localhost';

interface ConfigSet {
  endpoints: {
    apiEndpoint: string;
    publicAPIEndpoint: string;
    quickMatchApiEndpoint: string;
    surveyAPIEndpoint: string;
    TSEndpoint: string;
    cdnEndpoint: string;
    clientWebEndpoint: string;
    publicEndpoint: string;
  };
  stripe: {
    publicKey: string;
  };
  googleMaps: {
    publicKey: string;
  };
  analytics: {
    GA: {
      keyID: string;
      debugMode: boolean;
    };
    GTM: {
      keyID: string;
      auth: string;
      preview: string;
      debugMode: boolean;
    };
    mixpanel: {
      token: string;
      librarySRC?: string;
      apiHost?: string;
      tsAnalyticsEndpoint?: string;
    };
    VWO: {
      librarySRC: string;
      accountID: number;
      purchaseGoalID: number;
      registerGoalID: number;
      initiatedQuickmatchGoalID: number;
      qmLeadCaptureGoalID: number;
      selectedPlanGoalID: number;
      qmPurchaseGoalID: number;
      debugMode: boolean;
      oonExperimentCampaignID: number;
    };
  };
  featureFlags: {
    insuranceEligibilityOffers: boolean;
    accessible: boolean;
    countryStateDropDown: boolean;
    altAssessmentCTA: boolean;
  };
  launchDarkly: {
    sdkKey: string;
  };
  supportEmailLinks: {
    defaultSupport: string;
    partnersSupport: string;
    aetnaSupport: string;
    gatorcareSupport: string;
    verificationSupport: string;
  };
  i18n: {
    staticPath: string;
  };
}

const {
  REACT_APP_IS_MIME,
  REACT_APP_TS_API_HOST,
  REACT_APP_QM_API_HOST,
  REACT_APP_SURVEY_API_HOST,
  REACT_APP_CLIENT_WEB_HOST,
} = process.env;

const config: {
  local: ConfigSet;
  localTest: ConfigSet;
  mime: ConfigSet;
  test: ConfigSet;
  testBeta: ConfigSet;
  canary: ConfigSet;
  prod: ConfigSet;
} = {
  local: {
    endpoints: {
      apiEndpoint: 'http://localhost:3000',
      publicAPIEndpoint: 'http://localhost:3000',
      quickMatchApiEndpoint: 'http://localhost:3015/',
      surveyAPIEndpoint: 'http://localhost:3005',
      TSEndpoint: 'http://localhost:3000/',
      cdnEndpoint: 'https://cdn.dev.talkspace.com/',
      clientWebEndpoint: 'https://localhost:4001',
      publicEndpoint: 'http://localhost:3000/',
    },
    stripe: {
      publicKey: 'pk_test_ZiR80tYdDex98m382LP40yTg',
    },
    googleMaps: {
      publicKey: 'AIzaSyD4TQpBsK7ysHcRKB7eXqWkVAFsWPVoGX4',
    },
    analytics: {
      GA: {
        keyID: '',
        debugMode: false,
      },
      GTM: {
        keyID: 'GTM-MVMC99R',
        auth: 'IKud1OAmRzGibaGYLkUYZQ',
        preview: 'env-60',
        debugMode: true,
      },
      mixpanel: {
        token: 'fa79581068ccb3a9cd3cb59fbc760b0d',
        librarySRC: 'https://apx.dev.talkspace.com/tools/mp2.js',
        apiHost: 'https://apx.dev.talkspace.com/mp',
        tsAnalyticsEndpoint: 'http://localhost:3013/local',
      },
      VWO: {
        librarySRC: 'https://apxvo.dev.talkspace.com',
        accountID: 562325,
        purchaseGoalID: 200,
        registerGoalID: 201,
        initiatedQuickmatchGoalID: 203,
        qmLeadCaptureGoalID: 204,
        selectedPlanGoalID: 205,
        qmPurchaseGoalID: 214,
        debugMode: true,
        oonExperimentCampaignID: 16,
      },
    },
    featureFlags: {
      insuranceEligibilityOffers: true,
      accessible: true,
      countryStateDropDown: true,
      altAssessmentCTA: true,
    },
    launchDarkly: {
      sdkKey: '63188b38630c5d117991ce73',
    },
    supportEmailLinks: {
      defaultSupport: 'support@talkspace.com',
      partnersSupport: 'partners-support@talkspace.com',
      aetnaSupport: 'aetna-support@talkspace.com',
      gatorcareSupport: 'gatorcare-support@talkspace.com',
      verificationSupport: 'verification-support@talkspace.com',
    },
    i18n: {
      staticPath: '',
    },
  },
  /**
   * The `localTest` environment is used by Cypress when you run either of the following commands:
   *
   * - yarn run qa:open:local:test
   * - yarn run qa:run:local:test
   */
  localTest: {
    endpoints: {
      apiEndpoint: 'https://clientapi.dev.talkspace.com',
      publicAPIEndpoint: 'https://publicapi.dev.talkspace.com',
      quickMatchApiEndpoint: 'https://matchapi.dev.talkspace.com/',
      surveyAPIEndpoint: 'https://survey.dev.talkspace.com',
      TSEndpoint: 'https://clientapi.dev.talkspace.com/',
      cdnEndpoint: 'https://cdn.dev.talkspace.com/',
      clientWebEndpoint: 'https://app.dev.talkspace.com',
      publicEndpoint: 'https://publicapi.dev.talkspace.com/',
    },
    stripe: {
      publicKey: 'pk_test_ZiR80tYdDex98m382LP40yTg',
    },
    googleMaps: {
      publicKey: 'AIzaSyBeDp0J8UOf-cU6CSmhpPcTqP40pH_zCpo',
    },
    analytics: {
      GA: {
        keyID: '',
        debugMode: false,
      },
      GTM: {
        keyID: 'GTM-MVMC99R',
        auth: 'IKud1OAmRzGibaGYLkUYZQ',
        preview: 'env-60',
        debugMode: true,
      },
      mixpanel: {
        token: '50c9563ae17591f1111c48654ed7773d',
        librarySRC: 'https://apx.dev.talkspace.com/tools/mp2.js',
        apiHost: 'https://apx.dev.talkspace.com/mp',
        tsAnalyticsEndpoint: 'https://eventsapi.dev.talkspace.com',
      },
      VWO: {
        librarySRC: 'https://apxvo.dev.talkspace.com',
        accountID: 562325,
        purchaseGoalID: 200,
        registerGoalID: 201,
        initiatedQuickmatchGoalID: 203,
        qmLeadCaptureGoalID: 204,
        selectedPlanGoalID: 205,
        qmPurchaseGoalID: 214,
        debugMode: true,
        oonExperimentCampaignID: 86,
      },
    },
    featureFlags: {
      insuranceEligibilityOffers: true,
      accessible: true,
      countryStateDropDown: true,
      altAssessmentCTA: true,
    },
    launchDarkly: {
      sdkKey: '62c15ef8fe8f4a152d29e46c',
    },
    supportEmailLinks: {
      defaultSupport: 'support@talkspace.com',
      partnersSupport: 'partners-support@talkspace.com',
      aetnaSupport: 'aetna-support@talkspace.com',
      gatorcareSupport: 'gatorcare-support@talkspace.com',
      verificationSupport: 'verification-support@talkspace.com',
    },
    i18n: {
      staticPath: '',
    },
  },
  mime: {
    endpoints: {
      apiEndpoint: REACT_APP_TS_API_HOST as string,
      publicAPIEndpoint: REACT_APP_TS_API_HOST as string,
      quickMatchApiEndpoint: `${REACT_APP_QM_API_HOST}/` as string,
      surveyAPIEndpoint: REACT_APP_SURVEY_API_HOST as string,
      TSEndpoint: `${REACT_APP_TS_API_HOST}/` as string,
      cdnEndpoint: 'https://cdn.dev.talkspace.com/',
      clientWebEndpoint: REACT_APP_CLIENT_WEB_HOST as string,
      publicEndpoint: `${REACT_APP_TS_API_HOST}/` as string,
    },
    stripe: {
      publicKey: 'pk_test_ZiR80tYdDex98m382LP40yTg',
    },
    googleMaps: {
      publicKey: 'AIzaSyBeDp0J8UOf-cU6CSmhpPcTqP40pH_zCpo',
    },
    analytics: {
      GA: {
        keyID: '',
        debugMode: false,
      },
      GTM: {
        keyID: 'GTM-MVMC99R',
        auth: 'IKud1OAmRzGibaGYLkUYZQ',
        preview: 'env-60',
        debugMode: true,
      },
      mixpanel: {
        token: '50c9563ae17591f1111c48654ed7773d',
        librarySRC: 'https://apx.dev.talkspace.com/tools/mp2.js',
        apiHost: 'https://apx.dev.talkspace.com/mp',
        tsAnalyticsEndpoint: 'https://eventsapi.dev.talkspace.com',
      },
      VWO: {
        librarySRC: 'https://apxvo.dev.talkspace.com',
        accountID: 562328,
        purchaseGoalID: 200,
        registerGoalID: 201,
        initiatedQuickmatchGoalID: 203,
        qmLeadCaptureGoalID: 204,
        selectedPlanGoalID: 205,
        qmPurchaseGoalID: 214,
        debugMode: true,
        oonExperimentCampaignID: 86,
      },
    },
    featureFlags: {
      insuranceEligibilityOffers: true,
      accessible: true,
      countryStateDropDown: true,
      altAssessmentCTA: true,
    },
    launchDarkly: {
      sdkKey: '62c15ef8fe8f4a152d29e46c',
    },
    supportEmailLinks: {
      defaultSupport: 'support@talkspace.com',
      partnersSupport: 'partners-support@talkspace.com',
      aetnaSupport: 'aetna-support@talkspace.com',
      gatorcareSupport: 'gatorcare-support@talkspace.com',
      verificationSupport: 'verification-support@talkspace.com',
    },
    i18n: {
      staticPath: '',
    },
  },
  test: {
    endpoints: {
      apiEndpoint: 'https://clientapi.dev.talkspace.com',
      publicAPIEndpoint: 'https://publicapi.dev.talkspace.com',
      quickMatchApiEndpoint: 'https://matchapi.dev.talkspace.com/',
      surveyAPIEndpoint: 'https://survey.dev.talkspace.com',
      TSEndpoint: 'https://clientapi.dev.talkspace.com/',
      cdnEndpoint: 'https://cdn.dev.talkspace.com/',
      clientWebEndpoint: 'https://app.dev.talkspace.com',
      publicEndpoint: 'https://publicapi.dev.talkspace.com/',
    },
    stripe: {
      publicKey: 'pk_test_ZiR80tYdDex98m382LP40yTg',
    },
    googleMaps: {
      publicKey: 'AIzaSyBeDp0J8UOf-cU6CSmhpPcTqP40pH_zCpo',
    },
    analytics: {
      GA: {
        keyID: '',
        debugMode: false,
      },
      GTM: {
        keyID: 'GTM-MVMC99R',
        auth: 'IKud1OAmRzGibaGYLkUYZQ',
        preview: 'env-60',
        debugMode: true,
      },
      mixpanel: {
        token: '50c9563ae17591f1111c48654ed7773d',
        librarySRC: 'https://apx.dev.talkspace.com/tools/mp2.js',
        apiHost: 'https://apx.dev.talkspace.com/mp',
        tsAnalyticsEndpoint: 'https://eventsapi.dev.talkspace.com',
      },
      VWO: {
        librarySRC: 'https://apxvo.dev.talkspace.com',
        accountID: 562328,
        purchaseGoalID: 200,
        registerGoalID: 201,
        initiatedQuickmatchGoalID: 203,
        qmLeadCaptureGoalID: 204,
        selectedPlanGoalID: 205,
        qmPurchaseGoalID: 214,
        debugMode: true,
        oonExperimentCampaignID: 86,
      },
    },
    featureFlags: {
      insuranceEligibilityOffers: true,
      accessible: true,
      countryStateDropDown: true,
      altAssessmentCTA: true,
    },
    launchDarkly: {
      sdkKey: '62c15ef8fe8f4a152d29e46c',
    },
    supportEmailLinks: {
      defaultSupport: 'support@talkspace.com',
      partnersSupport: 'partners-support@talkspace.com',
      aetnaSupport: 'aetna-support@talkspace.com',
      gatorcareSupport: 'gatorcare-support@talkspace.com',
      verificationSupport: 'verification-support@talkspace.com',
    },
    i18n: {
      staticPath: '',
    },
  },
  testBeta: {
    endpoints: {
      apiEndpoint: 'https://clientapi.dev.talkspace.com',
      publicAPIEndpoint: 'https://publicapi.dev.talkspace.com',
      quickMatchApiEndpoint: 'https://matchapi.dev.talkspace.com/',
      surveyAPIEndpoint: 'https://survey.dev.talkspace.com',
      TSEndpoint: 'https://clientapi.dev.talkspace.com/',
      cdnEndpoint: 'https://cdn.dev.talkspace.com/',
      clientWebEndpoint: 'https://app-beta.dev.talkspace.com',
      publicEndpoint: 'https://publicapi.dev.talkspace.com/',
    },
    stripe: {
      publicKey: 'pk_test_ZiR80tYdDex98m382LP40yTg',
    },
    googleMaps: {
      publicKey: 'AIzaSyBeDp0J8UOf-cU6CSmhpPcTqP40pH_zCpo',
    },
    analytics: {
      GA: {
        keyID: '',
        debugMode: false,
      },
      GTM: {
        keyID: 'GTM-MVMC99R',
        auth: 'IKud1OAmRzGibaGYLkUYZQ',
        preview: 'env-60',
        debugMode: false,
      },
      mixpanel: {
        token: '50c9563ae17591f1111c48654ed7773d',
        librarySRC: 'https://apx.dev.talkspace.com/tools/mp2.js',
        apiHost: 'https://apx.dev.talkspace.com/mp',
        tsAnalyticsEndpoint: 'https://eventsapi.dev.talkspace.com',
      },
      VWO: {
        librarySRC: 'https://apxvo.dev.talkspace.com',
        accountID: 562328,
        purchaseGoalID: 200,
        registerGoalID: 201,
        initiatedQuickmatchGoalID: 203,
        qmLeadCaptureGoalID: 204,
        selectedPlanGoalID: 205,
        qmPurchaseGoalID: 214,
        debugMode: true,
        oonExperimentCampaignID: 86,
      },
    },
    featureFlags: {
      insuranceEligibilityOffers: true,
      accessible: true,
      countryStateDropDown: true,
      altAssessmentCTA: true,
    },
    launchDarkly: {
      sdkKey: '62c15ef8fe8f4a152d29e46c',
    },
    supportEmailLinks: {
      defaultSupport: 'support@talkspace.com',
      partnersSupport: 'partners-support@talkspace.com',
      aetnaSupport: 'aetna-support@talkspace.com',
      gatorcareSupport: 'gatorcare-support@talkspace.com',
      verificationSupport: 'verification-support@talkspace.com',
    },
    i18n: {
      staticPath: '',
    },
  },
  canary: {
    endpoints: {
      apiEndpoint: 'https://clientapi.canary.talkspace.com',
      publicAPIEndpoint: 'https://publicapi.canary.talkspace.com',
      quickMatchApiEndpoint: 'https://matchapi.canary.talkspace.com/',
      surveyAPIEndpoint: 'https://survey.canary.talkspace.com',
      TSEndpoint: 'https://clientapi.canary.talkspace.com/',
      cdnEndpoint: 'https://cdn.canary.talkspace.com/',
      clientWebEndpoint: 'https://app.canary.talkspace.com',
      publicEndpoint: 'https://publicapi.canary.talkspace.com/',
    },
    stripe: {
      publicKey: 'pk_test_cMoHUi7Z3bbE26WaLTAbawPU00IyzINpn3',
    },
    googleMaps: {
      publicKey: 'AIzaSyD9DxLYEAo7MeAjjO_87b7D2b3rtaFewu4',
    },
    analytics: {
      GA: {
        keyID: '',
        debugMode: false,
      },
      GTM: {
        keyID: 'GTM-MVMC99R',
        auth: 'fXPLgWth-pn19BkvLSn5mw',
        preview: 'env-59',
        debugMode: true,
      },
      mixpanel: {
        token: 'b2b83bd1e273fb7125936cc158c5e749',
        librarySRC: 'https://apx.canary.talkspace.com/tools/mp2.js',
        apiHost: 'https://apx.canary.talkspace.com/mp',
        tsAnalyticsEndpoint: 'https://eventsapi.canary.talkspace.com',
      },
      VWO: {
        librarySRC: 'https://apxvo.canary.talkspace.com',
        accountID: 562328,
        purchaseGoalID: 200,
        registerGoalID: 201,
        initiatedQuickmatchGoalID: 203,
        qmLeadCaptureGoalID: 204,
        selectedPlanGoalID: 205,
        qmPurchaseGoalID: 214,
        debugMode: true,
        oonExperimentCampaignID: 86,
      },
    },
    featureFlags: {
      insuranceEligibilityOffers: true,
      accessible: false,
      countryStateDropDown: true,
      altAssessmentCTA: true,
    },
    launchDarkly: {
      sdkKey: '63188b1d6dbe1c11ce5dd39c',
    },
    supportEmailLinks: {
      defaultSupport: 'support@talkspace.com',
      partnersSupport: 'partners-support@talkspace.com',
      aetnaSupport: 'aetna-support@talkspace.com',
      gatorcareSupport: 'gatorcare-support@talkspace.com',
      verificationSupport: 'verification-support@talkspace.com',
    },
    i18n: {
      staticPath: '',
    },
  },
  prod: {
    endpoints: {
      apiEndpoint: 'https://clientapi.talkspace.com',
      publicAPIEndpoint: 'https://publicapi.talkspace.com',
      quickMatchApiEndpoint: 'https://matchapi.talkspace.com/',
      surveyAPIEndpoint: 'https://survey.talkspace.com',
      TSEndpoint: 'https://clientapi.talkspace.com/',
      cdnEndpoint: 'https://cdn.talkspace.com/',
      clientWebEndpoint: 'https://app.talkspace.com',
      publicEndpoint: 'https://publicapi.talkspace.com/',
    },
    stripe: {
      publicKey: 'pk_live_k0WUCH8ZAmOwp2KpOnuTbQ8z',
    },
    googleMaps: {
      publicKey: 'AIzaSyBAlaStpXdAgctdhzOY5-BRLVpcglKRqLY',
    },
    analytics: {
      GA: {
        keyID: 'UA-31727584-1',
        debugMode: false,
      },
      GTM: {
        keyID: 'GTM-MVMC99R',
        auth: 'iU2bzUnldPa_87xyRp-2mw',
        preview: 'env-1',
        debugMode: false,
      },
      mixpanel: {
        token: 'c18be99a70104cfc390ad47fa5f44df6',
        librarySRC: 'https://apx.talkspace.com/tools/mp2.js',
        apiHost: 'https://apx.talkspace.com/mp',
        tsAnalyticsEndpoint: 'https://eventsapi.talkspace.com',
      },
      VWO: {
        librarySRC: 'https://apxvo.talkspace.com',
        accountID: 540341,
        purchaseGoalID: 200,
        registerGoalID: 201,
        initiatedQuickmatchGoalID: 203,
        qmLeadCaptureGoalID: 204,
        selectedPlanGoalID: 205,
        qmPurchaseGoalID: 214,
        debugMode: false,
        oonExperimentCampaignID: 79,
      },
    },
    featureFlags: {
      insuranceEligibilityOffers: true,
      accessible: false,
      countryStateDropDown: true,
      altAssessmentCTA: true,
    },
    launchDarkly: {
      sdkKey: '62c15ef8fe8f4a152d29e46d',
    },
    supportEmailLinks: {
      defaultSupport: 'support@talkspace.com',
      partnersSupport: 'partners-support@talkspace.com',
      aetnaSupport: 'aetna-support@talkspace.com',
      gatorcareSupport: 'gatorcare-support@talkspace.com',
      verificationSupport: 'verification-support@talkspace.com',
    },
    i18n: {
      staticPath: '',
    },
  },
};

const configSSL: Record<Environment, ConfigSet> = {
  ...config,
  localSSL: {
    ...config.local,
    endpoints: {
      apiEndpoint: `https://${SSLHostname}:8000/`,
      publicAPIEndpoint: `https://${SSLHostname}:8000/`,
      quickMatchApiEndpoint: `https://${SSLHostname}:8015/`,
      surveyAPIEndpoint: `https://${SSLHostname}:8005`,
      TSEndpoint: `https://${SSLHostname}:8000/`,
      cdnEndpoint: 'https://match.dev.talkspace.com/',
      clientWebEndpoint: `https://${SSLHostname}:8010`,
      publicEndpoint: `https://${SSLHostname}:8000/`,
    },
  },
};

export const detectEnv = (): Environment => {
  const origin = `${document.location.protocol}//${document.location.host}`;

  if ((window as any).Cypress?.env && (window as any).Cypress.env('env') === 'localTest') {
    return 'localTest';
  }

  if (REACT_APP_IS_MIME === '1') {
    return 'mime';
  }
  switch (origin) {
    case 'http://localhost:3001':
    case 'http://localhost:3014':
    case 'http://localhost.com:3014': // used by VWO
      return 'local';
    case `https://${SSLHostname}:8014`:
      return 'localSSL';
    case 'http://localhost:9999':
      return 'localTest';
    case 'https://match-beta.dev.talkspace.com':
      return 'testBeta';
    case 'https://match.dev.talkspace.com':
      return 'test';
    case 'https://match-beta.canary.talkspace.com':
    case 'https://match.canary.talkspace.com':
      return 'canary';
    default:
      return 'prod';
  }
};

const appConfig = configSSL[detectEnv()];

export default appConfig;
