import { ColorRolesVersion } from '../types';
import { COLORSV1, COLORS } from './colors';

export const ColorRolesV0: ColorRolesVersion = {
  system: {
    // actionPrimary
    actionPrimaryDefault: COLORS.green,
    actionPrimaryHovered: COLORS.green,
    actionPrimaryPressed: COLORS.green,
    actionPrimaryDisabled: COLORS.green,
    actionPrimaryText: COLORS.white,
    // actionSecondary
    actionSecondaryDefault: COLORS.white,
    actionSecondaryHovered: COLORS.white,
    actionSecondaryPressed: COLORS.white,
    actionSecondaryDisabled: COLORS.white,
    actionSecondaryText: COLORS.green,
    // interactivePrimary
    interactivePrimaryDefault: COLORS.green,
    interactivePrimaryHovered: COLORS.green,
    interactivePrimaryPressed: COLORS.green,
    interactivePrimaryDisabled: COLORS.extraLightGrey,
    interactivePrimaryText: COLORS.white,
    // borders
    borderSubtle: COLORS.permaLondonGray,
    borderDefault: COLORS.permaBaliHaiGrey,
  },
  element: {
    iconNonInteractiveDefault: COLORS.permaViridianGreen,
  },
  surfaces: {
    surfaceInteractiveDefault: COLORS.white,
    surfaceInteractiveSelected: COLORS.green,
    surfaceInteractiveHovered: COLORS.teal200,
    surfaceInteractivePressed: COLORS.teal400,
    surfaceInteractiveDisabled: COLORS.neutral500,
    surfaceInteractiveDisabledBold: COLORS.neutral700,
    default: COLORS.white,
    defaultHovered: COLORS.white,
    defaultPressed: COLORS.white,
    defaultDisabled: COLORS.white,
    defaultSubtle: COLORS.white,
    defaultSubtleDefault: COLORS.white,
    defaultSubtleHovered: COLORS.white,
    defaultSubtlePressed: COLORS.white,
    defaultSubtleDisabled: COLORS.white,
    neutralDefault: COLORS.permaGreyed,
    neutralHovered: COLORS.permaGreyed,
    neutralPressed: COLORS.permaGreyed,
    neutralDisabled: COLORS.permaGreyed,
    neutralBoldDefault: COLORS.neutral1000,
    brandDefault: COLORS.green,
    brandSubtleDefault: COLORS.green,
    brandBoldDefault: COLORS.green,
    successDefault: COLORS.green,
    successBoldDefault: COLORSV1.green600,
    warningDefault: COLORS.yellowSea,
    criticalBoldDefault: COLORS.redDamask,
    criticalSubtleDefault: COLORSV1.red100,
    infoDefault: COLORSV1.blue400,
    overlayDefault: COLORSV1.neutral800A,
    overlayBold: COLORS.neutral1000A,
    decorativeNeutral300: COLORS.neutral300,
    decorativeNeutral400: COLORS.neutral400,
    decorativePsych: COLORS.bizarre,
    decorativeTealExtralite: COLORS.aquaSqueeze,
    decorativeCouples: COLORS.catskillWhite,
    decorativeBalanceBlue: COLORS.balanceBlue,
    decorativeBalanceBlueTint: COLORS.balanceBlueTint,
    decorativeOptimistOrange: COLORS.optimistOrange,
    decorativeGratitudeGreen: COLORS.emerald,
    infoSubtle: COLORSV1.iceBlue,
    warningSubtleDefault: COLORS.yellow200,
    successSubtleDefault: COLORS.green200,
    successSubtleHovered: COLORS.successSubtleHovered,
    successSubtlePressed: COLORS.successSubtlePressed,
    decorativeLiftedLemon: COLORSV1.decorativeLiftedLemon,
    interactiveBoldDefault: COLORS.teal500,
    interactiveBoldHovered: COLORS.teal600,
    interactiveBoldPressed: COLORS.teal700,
    interactiveBoldDisabled: COLORS.neutral700,
    neutralSubtleDefault: COLORS.neutral100,
    criticalDefault: COLORS.surfaceCriticalDefault,
  },
  snowflake: {
    roomClosed: COLORS.grey600,
  },
  borders: {
    borderDefault: COLORS.lightGray,
    borderBrand: COLORS.green,
    borderInteractiveDefault: COLORS.lightGray,
    borderInteractiveSelectedBold: COLORS.green,
    borderSubtleDefault: COLORS.neutral400,
    borderDefaultDisabled: COLORS.neutral600,
  },
  typography: {
    textDefault: COLORS.neutral1000,
    textSubtle: COLORS.darkGray,
    textSubtlest: COLORS.neutral800,
    textInverse: COLORS.white,
    textInteractiveDefault: COLORS.green,
    textBrandDefault: COLORS.accessibilityGreenDark,
    textCriticalDefault: COLORSV1.red700,
    buttonBrandPrimary: COLORS.white,
    buttonBrandSecondary: COLORS.green,
    textSuccessDefault: COLORS.successGreen,
    textDefaultDisabled: COLORS.neutral700,
  },
  icons: {
    iconDefaultDefault: COLORS.neutral1000,
    iconDefaultDisabled: COLORS.neutral700,
    iconSubtleDefault: COLORS.neutral900,
    iconSubtleDisabled: COLORS.neutral700,
    iconSubtlestDefault: COLORS.neutral800,
    iconSubtlestDisabled: COLORS.neutral700,
    iconInverseDefault: COLORS.white,
    iconInverseDisabled: COLORS.white,
    iconBrandDefault: COLORS.teal700,
    iconBrandHovered: COLORS.teal800,
    iconBrandPressed: COLORS.teal900,
    iconBrandDisabled: COLORS.neutral700,
    iconCriticalDefault: COLORS.permaValencia,
    iconWarningDefault: COLORS.yellow600,
    iconSuccessDefault: COLORS.green600,
    iconInfoDefault: COLORS.blue600,
  },
  illustrations: {
    trustTeal: COLORS.teal700,
  },
  button: {
    // primary
    brandPrimaryTextDefault: COLORS.white,
    brandPrimaryIconDefault: COLORS.white,
    brandPrimarySurfaceDefault: COLORS.teal700,
    brandPrimarySurfaceHovered: COLORS.green,
    brandPrimarySurfacePressed: COLORS.green,
    brandPrimarySurfaceDisabled: COLORS.green,
    // secondary
    brandSecondaryTextDefault: COLORS.teal700,
    brandSecondaryTextHovered: COLORS.darkTeal,
    brandSecondaryTextPressed: COLORS.accessibilityDarkGreenBold,
    brandSecondaryTextDisabled: COLORS.neutral700,
    brandSecondaryIconDefault: COLORS.teal700,
    brandSecondaryIconHovered: COLORS.teal800,
    brandSecondaryIconPressed: COLORS.teal900,
    brandSecondarySurfaceDefault: COLORS.neutralTransparent,
    brandSecondarySurfaceHovered: COLORS.transparent,
    brandSecondarySurfacePressed: COLORS.transparent,
    brandSecondarySurfaceDisabled: COLORS.transparent,
    brandSecondaryBorderDefault: COLORS.teal700,
    brandSecondaryBorderHovered: COLORS.teal800,
    brandSecondaryBorderPressed: COLORS.teal900,
    brandSecondaryBorderDisabled: COLORS.neutral600,
    // tertiary
    brandTertiaryTextDefault: COLORS.teal700,
    brandTertiaryTextHovered: COLORS.teal800,
    brandTertiaryTextPressed: COLORS.teal900,
    brandTertiaryTextDisabled: COLORS.neutral700,
    brandTertiaryIconDefault: COLORS.teal700,
    brandTertiaryIconHovered: COLORS.teal800,
    brandTertiaryIconPressed: COLORS.teal900,
    brandTertiarySurfaceDefault: COLORS.neutralTransparent,
    brandTertiarySurfaceHovered: COLORS.neutral300,
    brandTertiarySurfacePressed: COLORS.neutral400,
  },
};

export const ColorRolesV1: ColorRolesVersion = {
  ...ColorRolesV0,
  system: {
    ...ColorRolesV0.system,
    // actionPrimary
    actionPrimaryDefault: COLORSV1.permaTalkspaceDarkGreen,
    actionPrimaryHovered: COLORSV1.permaTalkspaceDarkGreen,
    actionPrimaryPressed: COLORSV1.permaTalkspaceDarkGreen,
    actionPrimaryDisabled: COLORSV1.paleBluGray,
    actionPrimaryText: COLORSV1.white,
    // actionSecondary
    actionSecondaryDefault: COLORSV1.white,
    actionSecondaryHovered: COLORSV1.white,
    actionSecondaryPressed: COLORSV1.white,
    actionSecondaryDisabled: COLORSV1.white,
    actionSecondaryText: COLORSV1.permaTalkspaceDarkGreen,
    // interactivePrimary
    interactivePrimaryDefault: COLORSV1.interactiveDefaultGreen,
    interactivePrimaryHovered: COLORSV1.interactiveHoveredGreen,
    interactivePrimaryPressed: COLORSV1.accessibilityGreenDark,
    interactivePrimaryDisabled: COLORSV1.paleBluGray,
    interactivePrimaryText: COLORSV1.white,
    // borders
    borderSubtle: COLORSV1.permaLondonGray,
    borderDefault: COLORSV1.permaBaliHaiGrey,
  },
  element: {
    iconNonInteractiveDefault: COLORSV1.accessibilityGreenDark,
  },
  surfaces: {
    surfaceInteractiveDefault: COLORSV1.neutral0,
    surfaceInteractiveSelected: COLORSV1.teal500,
    surfaceInteractiveHovered: COLORSV1.teal200,
    surfaceInteractivePressed: COLORSV1.teal400,
    surfaceInteractiveDisabled: COLORSV1.neutral500,
    surfaceInteractiveDisabledBold: COLORSV1.neutral700,
    default: COLORSV1.neutralTransparent,
    defaultHovered: COLORSV1.neutral300,
    defaultPressed: COLORSV1.neutral400,
    defaultDisabled: COLORSV1.neutralTransparent,
    defaultSubtle: COLORSV1.neutral0,
    defaultSubtleDefault: COLORSV1.neutral0,
    defaultSubtleHovered: COLORSV1.neutral200,
    defaultSubtlePressed: COLORSV1.neutral300,
    defaultSubtleDisabled: COLORSV1.neutral400,
    neutralDefault: COLORSV1.neutral200,
    neutralHovered: COLORSV1.neutral300,
    neutralPressed: COLORSV1.neutral400,
    neutralDisabled: COLORSV1.neutral400,
    neutralBoldDefault: COLORSV1.neutral1000,
    brandDefault: COLORSV1.teal400,
    brandSubtleDefault: COLORSV1.teal100,
    brandBoldDefault: COLORSV1.teal700,
    successDefault: COLORSV1.green400,
    successBoldDefault: COLORSV1.green600,
    warningDefault: COLORSV1.yellow400,
    criticalBoldDefault: COLORSV1.red600,
    criticalSubtleDefault: COLORSV1.red100,
    infoDefault: COLORSV1.blue400,
    overlayDefault: COLORSV1.neutral800A,
    overlayBold: COLORS.neutral1000A,
    decorativeNeutral300: COLORSV1.neutral300,
    decorativeNeutral400: COLORSV1.neutral400,
    decorativeLiftedLemon: COLORSV1.decorativeLiftedLemon,
    decorativePsych: COLORSV1.decorativePsychRed,
    decorativeTealExtralite: COLORSV1.tealExtralite,
    decorativeCouples: COLORSV1.decorativeCouples,
    decorativeGratitudeGreen: COLORSV1.decorativeGratitudeGreen,
    infoSubtle: COLORSV1.iceBlue,
    warningSubtleDefault: COLORS.yellow200,
    successSubtleDefault: COLORS.green200,
    successSubtleHovered: COLORS.successSubtleHovered,
    successSubtlePressed: COLORS.successSubtlePressed,
    decorativeBalanceBlue: COLORSV1.decorativeBalanceBlue,
    decorativeBalanceBlueTint: COLORSV1.decorativeBalanceBlueTint,
    decorativeOptimistOrange: COLORSV1.decorativeOptimistOrange,
    interactiveBoldDefault: COLORSV1.teal500,
    interactiveBoldHovered: COLORSV1.teal600,
    interactiveBoldPressed: COLORSV1.teal700,
    interactiveBoldDisabled: COLORSV1.neutral700,
    neutralSubtleDefault: COLORS.neutral100,
    criticalDefault: COLORS.surfaceCriticalDefault,
  },
  snowflake: {
    roomClosed: COLORSV1.neutral400,
  },
  borders: {
    borderDefault: COLORSV1.neutral500,
    borderBrand: COLORSV1.teal700,
    borderInteractiveDefault: COLORSV1.neutral800,
    borderInteractiveSelectedBold: COLORSV1.teal500,
    borderSubtleDefault: COLORSV1.neutral400,
    borderDefaultDisabled: COLORS.neutral600,
  },
  typography: {
    textDefault: COLORSV1.neutral1000,
    textSubtle: COLORSV1.neutral900,
    textSubtlest: COLORSV1.neutral800,
    textInverse: COLORSV1.neutral0,
    textInteractiveDefault: COLORSV1.teal600,
    textBrandDefault: COLORSV1.teal700,
    textCriticalDefault: COLORSV1.red700,
    buttonBrandPrimary: COLORSV1.neutral0,
    buttonBrandSecondary: COLORSV1.teal700,
    textSuccessDefault: COLORS.successGreen,
    textDefaultDisabled: COLORS.neutral700,
  },
  icons: {
    iconDefaultDefault: COLORSV1.neutral1000,
    iconDefaultDisabled: COLORSV1.neutral700,
    iconSubtleDefault: COLORSV1.neutral900,
    iconSubtleDisabled: COLORSV1.neutral700,
    iconSubtlestDefault: COLORSV1.neutral800,
    iconSubtlestDisabled: COLORSV1.neutral700,
    iconInverseDefault: COLORSV1.neutral0,
    iconInverseDisabled: COLORSV1.white,
    iconBrandDefault: COLORSV1.teal700,
    iconBrandHovered: COLORSV1.teal800,
    iconBrandPressed: COLORSV1.teal900,
    iconBrandDisabled: COLORSV1.neutral700,
    iconCriticalDefault: COLORSV1.red600,
    iconWarningDefault: COLORSV1.yellow600,
    iconSuccessDefault: COLORSV1.green600,
    iconInfoDefault: COLORSV1.blue600,
  },
  illustrations: {
    trustTeal: COLORSV1.teal700,
  },
  button: {
    // primary
    brandPrimaryTextDefault: COLORSV1.neutral0,
    brandPrimaryIconDefault: COLORSV1.neutral0,
    brandPrimarySurfaceDefault: COLORSV1.teal700,
    brandPrimarySurfaceHovered: COLORSV1.teal800,
    brandPrimarySurfacePressed: COLORSV1.teal900,
    brandPrimarySurfaceDisabled: COLORSV1.neutral600,
    // secondary
    brandSecondaryTextDefault: COLORSV1.teal700,
    brandSecondaryTextHovered: COLORSV1.teal800,
    brandSecondaryTextPressed: COLORSV1.teal900,
    brandSecondaryTextDisabled: COLORSV1.neutral700,
    brandSecondaryIconDefault: COLORSV1.teal700,
    brandSecondaryIconHovered: COLORSV1.teal800,
    brandSecondaryIconPressed: COLORSV1.teal900,
    brandSecondarySurfaceDefault: COLORSV1.neutralTransparent,
    brandSecondarySurfaceHovered: COLORSV1.neutral300,
    brandSecondarySurfacePressed: COLORSV1.neutral400,
    brandSecondarySurfaceDisabled: COLORSV1.neutralTransparent,
    brandSecondaryBorderDefault: COLORSV1.teal700,
    brandSecondaryBorderHovered: COLORSV1.teal800,
    brandSecondaryBorderPressed: COLORSV1.teal900,
    brandSecondaryBorderDisabled: COLORSV1.neutral600,
    // tertiary
    brandTertiaryTextDefault: COLORSV1.teal700,
    brandTertiaryTextHovered: COLORSV1.teal800,
    brandTertiaryTextPressed: COLORSV1.teal900,
    brandTertiaryTextDisabled: COLORSV1.neutral700,
    brandTertiaryIconDefault: COLORSV1.teal700,
    brandTertiaryIconHovered: COLORSV1.teal800,
    brandTertiaryIconPressed: COLORSV1.teal900,
    brandTertiarySurfaceDefault: COLORSV1.neutralTransparent,
    brandTertiarySurfaceHovered: COLORSV1.neutral300,
    brandTertiarySurfacePressed: COLORSV1.neutral400,
  },
};

export const ColorRolesV2: ColorRolesVersion = {
  ...ColorRolesV1,
  system: {
    ...ColorRolesV1.system,
    // actionPrimary
    actionPrimaryDefault: COLORSV1.accessibilityGreenDark,
    actionPrimaryHovered: COLORSV1.accessibilityGreenDark,
    actionPrimaryPressed: COLORSV1.accessibilityGreenDark,
  },
};
