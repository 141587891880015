import { FunctionComponent, useState } from 'react';
import Svg, { G, Path, Defs, Circle, Mask, Use } from 'svgs';
import styled, { EmotionStyle } from '../../core/styled';
import View from '../View';
import Image from '../Image';

interface TSAvatarProps {
  avatarColor?: string;
  backgroundColor?: string;
  borderColor?: string;
  size?: number;
  titleText?: string;
  image?: string;
  imageStyle?: EmotionStyle;
  alt?: string;
  style?: EmotionStyle;
  onError?: (e?: Record<string, unknown>) => void;
  badge?: JSX.Element;
}

const ImageWrapper = styled(View)<{
  size: number | string;
}>(({ size }) => {
  return {
    borderRadius: '50%',
    overflow: 'hidden',
    width: size,
    height: size,
  };
});

const AvatarImage = styled(Image)({
  width: '100%',
  height: '100%',
  filter: 'brightness(95%)',
});

// This provides the default avatar as an svg as opposed to an image, which allows for more styling customization.
// If an image prop is provided (as with a therapist profile image), the image is rendered instead
const TSAvatar: FunctionComponent<TSAvatarProps> = ({
  avatarColor = '#009B8D',
  backgroundColor = '#EFF4F6',
  borderColor = '#EFF4F6',
  size = 70,
  titleText = 'avatar',
  image,
  alt = 'avatar',
  imageStyle = {},
  onError,
  badge,
  ...otherProps
}) => {
  const [isErrorLoadingImage, setIsErrorLoadingImage] = useState(false);
  // ensures that the default svg avatar is rendered if there is an error loading the provided image
  const handleError = (e) => {
    if (onError) onError(e);
    setIsErrorLoadingImage(true);
  };
  return image && !isErrorLoadingImage ? (
    <ImageWrapper size={size} {...otherProps}>
      <AvatarImage
        source={image}
        onError={handleError}
        style={{ height: size, width: size, ...imageStyle }}
        alt={alt}
      />
      {badge}
    </ImageWrapper>
  ) : (
    <>
      <Svg
        width={size}
        height={size}
        title={titleText}
        aria-label={titleText}
        viewBox="0 0 70 70"
        {...otherProps}
      >
        <Defs>
          <Path
            d="M30 60c16.569 0 30-13.431 30-30 0-7.464-2.726-14.29-7.236-19.54a30.17 30.17 0 00-5.54-5.027A29.862 29.862 0 0030 0C13.431 0 0 13.431 0 30c0 16.569 13.431 30 30 30z"
            id="TSAvatar__a"
          />
          <Path id="TSAvatar__e" d="M-8.229-10.971h79.543v76.457H-8.229z" />
          <Circle id="TSAvatar__c" cx={30} cy={30} r={30} fill="none" />
        </Defs>
        <G fill={backgroundColor} fillRule="evenodd">
          <G transform="translate(5.118 5)">
            <Mask id="TSAvatar__b" fill="#fff">
              <Use xlinkHref="#TSAvatar__a" />
            </Mask>
            <Path
              stroke={borderColor}
              strokeWidth={4.235}
              d="M30-2.118a31.973 31.973 0 0118.44 5.819 32.288 32.288 0 015.93 5.379A31.993 31.993 0 0162.119 30c0 8.869-3.595 16.898-9.407 22.71-5.813 5.813-13.842 9.408-22.711 9.408-8.869 0-16.898-3.595-22.71-9.407C1.476 46.898-2.119 38.869-2.119 30c0-8.869 3.595-16.898 9.407-22.71C13.102 1.476 21.131-2.119 30-2.119z"
            />
            <Path
              d="M39.604 40.244H20.397c-1.033 0-1.797-1.02-1.407-1.958 1.81-4.338 6.06-6.823 11.01-6.823 4.952 0 9.202 2.485 11.01 6.823.39.938-.373 1.958-1.406 1.958M24.024 22.683c0-3.228 2.682-5.854 5.976-5.854 3.295 0 5.975 2.626 5.975 5.854s-2.68 5.854-5.975 5.854c-3.294 0-5.976-2.626-5.976-5.854"
              fill={avatarColor}
              mask="url(#TSAvatar__b)"
            />
          </G>
        </G>
      </Svg>
      {badge}
    </>
  );
};

export default TSAvatar;
