/**
 * Flow: 147
 * Description: Teens DTE therapy - Experimental Optimized Flow from 128;
 *
 * Notes:
 *
 */
import { Standard, Tiny, FindingTheRightMatchShield, BrainShield } from '@talkspace/react-toolkit';

import styled from '@/core/styled';

import { HomePageState } from '../Components/HomePage/types';
import getParamByName from '../utils/queryString';
// This import in particular needs user interaction, therefore, "safe" to be a cycle
// eslint-disable-next-line import/no-cycle
import { getClientFlow } from './index';
// eslint-disable-next-line import/no-cycle
import { FLOW_138_B2B_VOUCHER_GENERIC_V2 } from './Flow138';
// eslint-disable-next-line import/no-cycle
import { FLOW_7_B2B_COPAY_GENERIC } from './Flow7';

import { EligibilityType, QMFlow, UpdateStepObj } from './types';
import {
  allRegistrationPagesSteps,
  initFlowConfig,
  registrationStepsValidation,
  languageMatchingButtons,
  languageMatchingSkipIfNoLanguages,
  languageMatchingSelfServe,
  languageMatchingFilterHeading1,
  languageMatchingFilterHeading2,
  languageMatchingFilterButtons,
  routeToLanguageMatchingIfExperiment,
  skipToStepIfAnswerValue,
} from './utils';
import { LANGUAGE_ID_ENGLISH } from '../Helpers/matchingLanguages';

const OFFER_ID = 62;
const INITIAL_OFFER_STEP_ID = 24;

const Styled = {
  TipTitle: styled(Standard)(({ theme: { colors } }) => {
    return {
      color: colors.permaFernGreen,
      fontWeight: 700,
    };
  }),
  TipContent: styled(Tiny)(({ theme: { colors } }) => {
    return {
      color: colors.grey950,
      marginTop: 4,
    };
  }),
};

export const getRegistrationStepIDByFlowID = (
  state: HomePageState,
  answer?: UpdateStepObj
): number => {
  const { organizationFlowID, partnerFlowID } = state;
  let { qmPartnerCode, cpPartnerCode } = state;
  let accessCodeType = getParamByName('accessCodeType') as AccessCodeType | null;

  if (
    !(accessCodeType || qmPartnerCode || cpPartnerCode) &&
    (answer?.accessCodeType || answer?.accessCode)
  ) {
    accessCodeType = answer.accessCodeType || null;
    // Attempt to find the cpPartnerCode first
    if (accessCodeType && accessCodeType === 'cpPartnerCode') {
      cpPartnerCode = answer.accessCode || '';
    }
    // Default to qmPartnerCode
    if (!cpPartnerCode) {
      qmPartnerCode = answer.accessCode || '';
    }
  }
  const flowID = organizationFlowID || partnerFlowID;
  let flow = flowID && getClientFlow(flowID);
  if (cpPartnerCode || accessCodeType === 'cpPartnerCode') {
    flow = getClientFlow(FLOW_7_B2B_COPAY_GENERIC);
  }
  if (qmPartnerCode || accessCodeType === 'qmPartnerCode') {
    flow = getClientFlow(FLOW_138_B2B_VOUCHER_GENERIC_V2);
  }
  if (flow) {
    const registrationStepIndexes = flow.steps
      .map((step) => registrationStepsValidation.findIndex((validationFun) => validationFun(step)))
      .filter((index) => index > -1);
    if (registrationStepIndexes.length > 0) {
      return INITIAL_OFFER_STEP_ID + registrationStepIndexes[0];
    }
  }
  return INITIAL_OFFER_STEP_ID;
};

/** Service Specific Funnels: Therapy */
export const FLOW_147_TEENSPACE_OPTIMIZED = 147;

const progressMax = 9;
const flow: QMFlow = {
  flowId: FLOW_147_TEENSPACE_OPTIMIZED,
  flowConfig: {
    ...initFlowConfig,
    serviceType: 'therapyTeen',
    isB2B: true,
    isTeen: true,
    eligibilityType: EligibilityType.zipCodeEligibility,
    version: 2,
  },
  steps: [
    {
      id: 1,
      category: 'teensHereToHelp',
      isInterstitial: true,
      buttonTarget: 2,
    },
    {
      id: 2,
      analyticsId: 54,
      category: 'teenZipcodeEligibility',
      buttonTarget: 3,
      response_category_id: 9,
    },
    {
      id: 3,
      category: 'teensEligibleFreeTherapy',
      isInterstitial: true,
      buttonTarget: 4,
      noFooter: true,
    },
    {
      id: 4,
      category: 'presentingProblems',
      analyticsId: 54,
      response_category_id: 2,
      isMulti: true,
      heading2: 'What do you need support with?',
      heading3: 'Select all that apply',
      prompt: 'What do you need support with?',
      selfServe: {
        field: 'fieldsOfExpertise',
        type: Array,
      },
      buttonTarget: 5,
      tip: 'custom',
      tipImage: <FindingTheRightMatchShield />,
      tipTitle: <Styled.TipTitle>Finding the right match</Styled.TipTitle>,
      tipContent: (
        <Styled.TipContent>
          We’ll match you to a therapist who can offer support tailored to your needs
        </Styled.TipContent>
      ),
      progressCurrent: 2,
      progressMax,
    },
    {
      id: 5,
      category: 'teenYouAreNotAlone',
      isInterstitial: true,
      buttonTarget: 7,
      progressCurrent: 3,
      progressMax,
    },
    {
      id: 7,
      prompt: 'Have you talked to your parent or guardian about doing therapy?',
      analyticsId: 98,
      response_category_id: 7,
      category: 'select1',
      heading2: 'Have you talked to your parent or guardian about doing therapy?',
      buttons: [
        {
          text: 'Yes',
          externalTarget: null,
          internalTarget: 8,
          answerValue: 'Yes',
        },
        {
          text: 'No',
          externalTarget: null,
          internalTarget: 8,
          answerValue: 'No',
        },
      ],
      skipText: 'Skip',
      skipInternalTarget: 8,
      progressCurrent: 4,
      progressMax,
    },
    {
      id: 8,
      category: 'teenConsentHeadsUp',
      isInterstitial: true,
      buttonTarget: 9,
      progressCurrent: 5,
      progressMax,
    },
    {
      id: 9,
      category: 'select1',
      heading2: 'How do you feel about doing therapy?',
      prompt: 'How do you feel about doing therapy?',
      analyticsId: 99,
      heading3: 'Select all that apply',
      isMulti: true,
      response_category_id: 7,
      buttons: [
        { text: 'Nervous', answerValue: 'Nervous' },
        { text: 'Excited', answerValue: 'Excited' },
        { text: 'Calm', answerValue: 'Calm' },
        { text: 'Confident', answerValue: 'Confident' },
        { text: 'Relieved', answerValue: 'Relieved' },
        { text: 'Scared', answerValue: 'Scared' },
        { text: 'Neutral', answerValue: 'Neutral' },
        { text: 'I’m not sure', answerValue: 'I’m not sure' },
        { text: 'Other', answerValue: 'Other' },
      ],
      buttonTarget: 11,
      tip: 'custom',
      tipImage: <BrainShield width={90} height={90} />,
      tipTitle: <Styled.TipTitle>Did you know...</Styled.TipTitle>,
      tipContent: (
        <Styled.TipContent>
          Therapy is especially effective for teens since their brains are highly adaptable
        </Styled.TipContent>
      ),
      skipText: 'Skip',
      skipInternalTarget: 11,
      progressCurrent: 6,
      progressMax,
    },
    {
      id: 11,
      prompt: 'Preferred provider gender',
      analyticsId: 57,
      response_category_id: 3,
      category: 'select1',
      heading2: 'What gender do you prefer in a therapist?',
      selfServe: {
        field: 'therapistGender',
      },
      buttons: [
        {
          text: 'No preference',
          externalTarget: null,
          internalTarget: 12,
          answerValue: undefined,
        },
        {
          text: 'Female',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 2,
        },
        {
          text: 'Male',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 1,
        },
        {
          text: "I'm not sure",
          externalTarget: null,
          internalTarget: 12,
          answerValue: undefined,
        },
      ],
      progressCurrent: 7,
      progressMax,
      nextQuestionActions: {
        12: [routeToLanguageMatchingIfExperiment(190, 12)],
      },
    },
    {
      id: 190,
      prompt: 'What’s your preferred language?',
      analyticsId: 140,
      response_category_id: 7,
      category: 'selectDynamic',
      dataDependencies: [{ key: 'matchingLanguages', options: { forceRefetch: true } }],
      heading1: 'What’s your preferred language?',
      heading2: 'Based on the state you selected, there are providers who speak these languages:',
      buttons: languageMatchingButtons(191),
      skipQuestionActions: [languageMatchingSkipIfNoLanguages(12)],
      nextQuestionActions: {
        191: [
          (state, answer, flags) => {
            if (answer.response_value === LANGUAGE_ID_ENGLISH) {
              return 12;
            }
            return undefined;
          },
        ],
      },
      progressCurrent: 7,
      progressMax,
    },
    {
      id: 191,
      // This prompt does not show to users, but is used in analytics, the "[language]" part is intentional
      prompt: 'Are you willing to wait for a [language]-speaking provider?',
      analyticsId: 141,
      response_category_id: 7,
      category: 'selectDynamic',
      selfServe: languageMatchingSelfServe(190, 191),
      heading1: languageMatchingFilterHeading1(190),
      heading2: languageMatchingFilterHeading2(190),
      buttons: languageMatchingFilterButtons(12),
      skipQuestionActions: [skipToStepIfAnswerValue(12, `${LANGUAGE_ID_ENGLISH}`)],
      progressCurrent: 8,
      progressMax,
    },
    {
      id: 12,
      category: 'teenThankYouForSharing',
      isInterstitial: true,
      buttonTarget: INITIAL_OFFER_STEP_ID,
      progressCurrent: 9,
      progressMax,
      nextQuestionActions: [getRegistrationStepIDByFlowID],
    },
    ...allRegistrationPagesSteps({
      initialStepID: INITIAL_OFFER_STEP_ID,
      offersPageSettings: {
        offerID: OFFER_ID,
        previousStepsUntilMatches: 2,
      },
      registerWithVoucherSettings: { isNYCTeen: true },
    }),
  ],
};

export default flow;
