import { FunctionComponent } from 'react';
import {
  GreenCircleCheckMark,
  View,
  useEmotionTheme,
  useContainerFocus,
  useUniqueID,
  Big,
  ExtraHuge,
  Button,
  commonStyles,
  RedX,
} from '@talkspace/react-toolkit';
import moment from 'moment-timezone';
import { ServiceType } from 'ts-frontend/types';
import { withRouter, RouteComponentProps } from '@/core/routerLib';
import { FLOW_33_B2B_BH_FILE_GATORCARE } from '../../Flows';
import switchFlowHelper from '../../Helpers/switchFlowHelper';
import { FlowConfig, ValidateVoucherStep } from '../../Flows/types';
import Assistance from '../Assistance/Assistance';
import styled from '../../core/styled';
import { AccountType } from '../HomePage/types';

const { QMResponsiveWidth } = commonStyles;

interface ValidateVoucherProps extends RouteComponentProps {
  updateAndSave: (state: Partial<RecoveredSessionState>) => void;
  clientDateOfBirth?: string;
  invalidVoucher: boolean;
  flowId: number;
  flowConfig?: FlowConfig;
  step: ValidateVoucherStep;
  goBack: () => void;
  voucher: string;
  insuranceCode: string | null | undefined;
  trizettoRequest: string | number | null | undefined;
  copayCents: string | undefined;
  service: ServiceType | null;
  trizettoErrorStatusCode?: number;
  isVideoOnlyPlan: boolean;
  totalSessions: number | undefined;
  accountType: AccountType | null;
  manualFlowID: number | undefined;
}

const StyledContainer = styled(View)({
  height: '100%',
  width: '100%',
  margin: 'auto',
  maxWidth: 335,
  alignItems: 'center',
  '&:focus': {
    outline: 'none',
  },
});

const ValidateVoucher: FunctionComponent<ValidateVoucherProps> = ({
  updateAndSave,
  clientDateOfBirth,
  invalidVoucher,
  flowId,
  flowConfig,
  step,
  goBack,
  voucher,
  insuranceCode,
  trizettoRequest,
  copayCents,
  service,
  trizettoErrorStatusCode,
  isVideoOnlyPlan,
  history,
  totalSessions,
  accountType,
  manualFlowID,
}) => {
  const { containerRef } = useContainerFocus();
  const header1ID = useUniqueID('header1ID');
  const header2ID = useUniqueID('header2ID');
  const { colors } = useEmotionTheme();

  const voucherNextStep = () => {
    if (invalidVoucher) {
      goBack();
    } else if (flowConfig && 'isTrizetto' in flowConfig && flowConfig.isTrizetto) {
      let voucherType: string;
      let targetFlowID: number;
      if (insuranceCode) {
        // from Trizetto
        voucherType = 'cpPartnerCode';
        targetFlowID = 7;
      } else {
        // from timeout Trizetto, fallback to manual
        voucherType = 'qmPartnerCode';
        targetFlowID = 2;
      }

      const queryString = new URLSearchParams();
      queryString.set('redirectFrom', String(flowId));
      queryString.set(voucherType, voucher);
      if (service) {
        queryString.set('serviceType', service);
      }
      if (clientDateOfBirth) {
        // NOTE: clientAge is being set with client date of birth
        queryString.set('clientAge', moment(clientDateOfBirth).format('YYYY-MM-DD'));
      }
      if (trizettoRequest) {
        queryString.set('tr', String(trizettoRequest));
      }
      if (insuranceCode) {
        queryString.set('insuranceCode', insuranceCode);
      }
      if (copayCents) {
        queryString.set('cpc', copayCents);
      }
      if (isVideoOnlyPlan) {
        queryString.set('isVideoOnlyPlan', String(isVideoOnlyPlan));
      }
      if (totalSessions) {
        queryString.set('totalSessions', String(totalSessions));
      }
      if (accountType) {
        queryString.set('accountType', accountType);
      }
      if (manualFlowID) {
        queryString.set('manualFlowID', String(manualFlowID));
      }

      switchFlowHelper(targetFlowID, history, updateAndSave, {
        stepId: 2,
        newFlowSearch: `?${queryString.toString()}`,
      });
    } else {
      history.replace(`/?qmPartnerCode=${voucher}`);
    }
  };

  const getErrorMessage = () => {
    switch (trizettoErrorStatusCode) {
      case 500:
        return {
          title: 'There is a problem verifying eligibility at this moment.',
          subtitle: 'Please try again later.',
        };
      case 404:
        return {
          title: 'We’re unable to locate your coverage',
          subtitle: `Unfortunately, we can’t verify your coverage. Please try again or contact your health insurance provider to confirm if Talkspace is a covered service`,
        };
      default:
        // the following message is displayed for non-trizetto flows too in case of a generic error
        return {
          title: 'There was an issue validating your information!',
          subtitle: 'Please check that the information you’ve entered is correct and try again.',
        };
    }
  };

  const isValid = voucher && !invalidVoucher && !trizettoErrorStatusCode;

  return (
    <StyledContainer tabIndex={-1} ref={containerRef} aria-labelledby={header1ID}>
      {isValid ? (
        <GreenCircleCheckMark height={66} width={66} style={{ marginBottom: 20 }} />
      ) : (
        <RedX style={{ marginBottom: 20 }} />
      )}
      <ExtraHuge
        id={header1ID}
        as="h1"
        style={{ textAlign: 'center', fontWeight: 300, fontSize: 26 }}
      >
        {isValid ? step.heading2 : getErrorMessage().title}
      </ExtraHuge>
      <Big
        id={header2ID}
        as="h2"
        style={{
          textAlign: 'center',
          fontWeight: 400,
          color: colors.permaWaikawaGrey,
          paddingTop: 15,
          paddingBottom: 15,
        }}
      >
        {isValid ? step.heading3 : getErrorMessage().subtitle}
      </Big>
      {flowId === FLOW_33_B2B_BH_FILE_GATORCARE && !invalidVoucher ? null : (
        <Button
          aria-describedby={header2ID}
          onPress={voucherNextStep}
          style={QMResponsiveWidth}
          text={isValid ? step.buttonText : 'Go Back'}
          primaryColor={colors.green}
          roundedFocusStyle
          data-qa="nextBackButton"
        />
      )}
      {/* Display support info from step config */}
      {step.supportEmail && step.linkToFAQ && (!voucher || invalidVoucher) && (
        <Assistance supportEmail={step.supportEmail} linkToFAQ={step.linkToFAQ} />
      )}
    </StyledContainer>
  );
};

export default withRouter(ValidateVoucher);
