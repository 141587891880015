import { Trans } from '@talkspace/i18n';
import useTranslation from '@talkspace/i18n/hooks/useTranslation';
import useStringTranslation from '@talkspace/i18n/hooks/useStringTranslation';

import { useUniqueID } from '../../hooks/a11yHelper';
import TextDS from '../../designSystems/components/typography/TextDS';
import Link from '../Link';
import HiddenText from '../HiddenText';
import { EmotionStyle, useEmotionTheme } from '../../core/styled';
import EmotionThemeProvider from '../../hooks/EmotionThemeProvider';

interface Props {
  isLoggedIn?: boolean;
  style?: EmotionStyle;
  dataQa?: string;
}
const EmergencyDisclaimer = ({ isLoggedIn, style, dataQa }: Props) => {
  const emergencyInfoID = useUniqueID('emergencyInfoID');
  const newTabInfoID = useUniqueID('newTabInfoID');
  const { colors } = useEmotionTheme();

  const { t: tReactToolkit } = useTranslation('react-toolkit');
  const resourcesLinkText = useStringTranslation(
    'these resources',
    'react-toolkit',
    'EmergencyDisclaimer.resourcesLinkText'
  );
  return (
    <EmotionThemeProvider version="2.0.0">
      <TextDS variant="bodyXs" colorRole="textSubtlest" id={emergencyInfoID} style={style}>
        <Trans t={tReactToolkit} i18nKey="EmergencyDisclaimer.bodyText">
          If you’re in a life-threatening situation,{' '}
          <TextDS inline variant="headingXs">
            don’t use this app
          </TextDS>
          . Call 988 {isLoggedIn ? 'or +1(833) 929-1721' : ''} or use
          <Link
            dataQa={dataQa}
            isNested
            text={
              <TextDS inline variant="linkXs" colorRole="textInteractiveDefault">
                {resourcesLinkText}
              </TextDS>
            }
            aria-describedby={`${emergencyInfoID} ${newTabInfoID}`}
            href="https://helpnow.talkspace.com/"
            target="_blank"
            primaryColor={colors.permaTalkspaceDarkGreen}
            roundedFocusStyle
            style={{
              textDecoration: 'none',
              fontWeight: 'bold',
              textDecorationLine: 'underline',
              marginRight: 0,
            }}
          />
          .
        </Trans>
      </TextDS>
      <HiddenText id={newTabInfoID}>opens in new tab</HiddenText>
    </EmotionThemeProvider>
  );
};

export default EmergencyDisclaimer;
