import { FindingTheRightMatchShield, Standard, Tiny } from '@talkspace/react-toolkit';
import { Trans } from '@talkspace/i18n';
import styled from '@/core/styled';
import { isUnder18 } from '../Helpers/ageHelper';
import { BaseStep, FlowStep, PresentingProblemsStep, PresentingProblemsMultiStep } from './types';

const Styled = {
  TipTitle: styled(Standard)(({ theme: { colors } }) => {
    return {
      color: colors.permaFernGreen,
      fontWeight: 700,
    };
  }),
  TipContent: styled(Tiny)(({ theme: { colors } }) => {
    return {
      color: colors.grey950,
      marginTop: 4,
    };
  }),
};

type GetPresentingProblemStepsConfig = {
  singleStepID: number;
  target: number;
  multiStepID?: number;
  heading2?: string;
};

// eslint-disable-next-line import/prefer-default-export
export const presentingProblemSteps = (
  config: GetPresentingProblemStepsConfig,
  stepSettings?: Partial<BaseStep>
): Array<FlowStep> => {
  const { singleStepID, target, multiStepID, heading2 } = config;
  const steps: Array<FlowStep> = [];
  const singlePresentingProblemStep: PresentingProblemsStep = {
    id: singleStepID,
    prompt: 'What led you to seek help?',
    analyticsId: 54,
    response_category_id: 2,
    heading1: null,
    heading2:
      heading2 || 'To begin, please select why you thought about getting help from a provider',
    heading2TranslationKey: heading2
      ? undefined
      : 'quickmatch.common:presentingProblemsStep.heading2Default',
    category: 'presentingProblems',
    internalTarget: target,
    selfServe: {
      field: 'fieldsOfExpertise',
      type: Array,
    },
    tip: 'findTheRightMatch',
    ...(stepSettings as Partial<PresentingProblemsStep>),
  };

  if (multiStepID) {
    const multiPresentingProblemsStep: PresentingProblemsMultiStep = {
      id: multiStepID,
      analyticsId: 54,
      response_category_id: 2,
      isMulti: true,
      heading2: 'What do you need support with?',
      heading2TranslationKey: 'quickmatch.common:presentingProblemsStep.heading2Multi',
      heading3: 'Select all that apply',
      heading3TranslationKey: 'quickmatch.common:presentingProblemsStep.heading3Multi',
      prompt: 'What do you need support with?',
      category: 'presentingProblems',
      buttonTarget: target,
      selfServe: {
        field: 'fieldsOfExpertise',
        type: Array,
      },
      tip: 'custom',
      tipImage: <FindingTheRightMatchShield />,
      tipTitle: (
        <Styled.TipTitle>
          <Trans i18nKey="quickmatch.common:presentingProblemsStep.tipTitleMulti">
            Finding the right match
          </Trans>
        </Styled.TipTitle>
      ),
      tipContent: (
        <Styled.TipContent>
          <Trans i18nKey="quickmatch.common:presentingProblemsStep.tipContentMulti">
            We’ll match you to a therapist who can offer support tailored to your needs
          </Trans>
        </Styled.TipContent>
      ),
      ...(stepSettings as Partial<PresentingProblemsMultiStep>),
    };

    // Merge skipQuestionActions
    singlePresentingProblemStep.skipQuestionActions = [
      ...(singlePresentingProblemStep.skipQuestionActions || []),
      (state) => {
        if (
          (state.clientDateOfBirth && isUnder18(state.clientDateOfBirth)) ||
          (state.clientAgeNumber && state.clientAgeNumber < 18)
        ) {
          return multiStepID;
        }
        return undefined;
      },
    ];

    steps.push(multiPresentingProblemsStep);
  }

  steps.push(singlePresentingProblemStep);

  return steps;
};
