import {
  simplifiedPresentingProblems,
  simplifiedPsychiatryPresentingProblems,
  teenPresentingProblems,
  presentingProblemsIsraelFlow,
} from 'ts-frontend/helpers/presentingProblems';
import { ClientMatchPresentingProblem, ServiceType } from 'ts-frontend/types';
import {
  EligibilityType,
  FLOW_133_ISRAEL,
  PresentingProblemsMultiStep,
  PresentingProblemsStep,
} from '../Flows';
import { isUnder18 } from './ageHelper';

interface GetPresentingProblemListParams {
  serviceType: ServiceType | undefined;
  eligibilityType: EligibilityType | undefined;
  secondaryServiceSource?: ServiceType | null;
  flowId: number;
  clientDateOfBirth?: string;
  clientAgeNumber?: number;
  step: PresentingProblemsStep | PresentingProblemsMultiStep;
}

// eslint-disable-next-line import/prefer-default-export
export const getPresentingProblemList = ({
  serviceType,
  eligibilityType,
  secondaryServiceSource,
  flowId,
  clientDateOfBirth,
  clientAgeNumber,
  step,
}: GetPresentingProblemListParams): ClientMatchPresentingProblem[] => {
  if (flowId === FLOW_133_ISRAEL) {
    return presentingProblemsIsraelFlow;
  }

  if (serviceType === 'psychiatry' || secondaryServiceSource === 'psychiatry') {
    return simplifiedPsychiatryPresentingProblems;
  }

  const isTeenAge =
    (clientDateOfBirth && isUnder18(clientDateOfBirth)) || (clientAgeNumber ?? 21) < 18;

  if (
    (serviceType === 'therapyTeen' && eligibilityType === EligibilityType.zipCodeEligibility) ||
    (isTeenAge && 'isMulti' in step && step.isMulti)
  ) {
    return teenPresentingProblems;
  }
  return simplifiedPresentingProblems;
};
