import { FunctionComponent, useRef, useState, useEffect, useCallback } from 'react';
import * as React from 'react';
import styled, { EmotionStyle } from '../../core/styled';
import View from '../View';
import Input from '../Input';
import { useErrorFocus } from '../../hooks/a11yHelper';

const StyledInput = styled(Input)<{ isError?: boolean }>(({ theme: { colors }, isError }) => {
  return {
    outline: 'none',
    borderRadius: 10,
    height: 55,
    width: 48,
    caretColor: colors.permaViridianGreen,
    paddingLeft: 0,
    textAlign: 'center',
    border: `1px solid ${isError ? colors.permaFuchsia : colors.permaWildBlueYonder}`,
    MozAppearance: 'none',
    '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    color: colors.black,
    fontSize: 29,
    fontWeight: 400,
    lineHeight: '34px',
  };
});

const VerificationCodeInputContainer = styled(View)({
  flexDirection: 'row',
  justifyContent: 'center',
  columnGap: 6,
});

interface VerificationCodeInputProps {
  isError: boolean;
  ariaRequired?: boolean;
  ariaDescribedBy?: string;
  onChange: (verificationCode: string) => void;
  clearVerificationCodeError?: () => void;
  onSubmit?: (verificationCode: string) => void;
  allowLetters?: boolean;
  inputLength?: number;
  style?: EmotionStyle;
  clearVerificationCode?: boolean;
  autoFocus?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  /** Calls the onSubmit method if all 6 fields are set */
  submitAutomatically?: boolean;
}

const VerificationCodeInput: FunctionComponent<VerificationCodeInputProps> = ({
  isError,
  ariaRequired = false,
  ariaDescribedBy,
  onChange,
  clearVerificationCodeError,
  onSubmit,
  allowLetters = false,
  inputLength = 6,
  style = {},
  clearVerificationCode = false,
  autoFocus = false,
  isLoading = false,
  isDisabled = false,
  submitAutomatically = false,
}) => {
  const initialValue = [...Array(inputLength)].map(() => '');
  const initialInputRefsArr = [...Array(inputLength)].map(() => null);
  const [verificationCode, setVerificationCode] = useState<Array<string>>(initialValue);
  const inputRefs = useRef<Array<HTMLInputElement | null>>(initialInputRefsArr);
  const alphaNumericRegex = /^[a-zA-Z0-9]+$/;
  const { formContainerRef, setShouldFocusFirstInvalidField } = useErrorFocus();

  const isVerificationCodeInvalid = useCallback(
    () =>
      verificationCode.some((element) => element === '') || verificationCode.length !== inputLength,
    [verificationCode, inputLength]
  );

  const insertPastedCode = (pastedCode: string) => {
    if (isDisabled) {
      return;
    }

    const verificationCodeCopy = [...initialValue];
    const pastedCodeArr = pastedCode.split('');

    if (
      (!allowLetters && pastedCodeArr.some((c) => !Number.isFinite(Number(c)))) ||
      !alphaNumericRegex.test(pastedCode)
    ) {
      return;
    }

    for (let i = 0; i < inputLength; i += 1) {
      verificationCodeCopy[i] = pastedCodeArr[i] || '';
    }
    setVerificationCode(verificationCodeCopy);
    inputRefs.current[pastedCodeArr.length - 1]?.focus();
  };

  const onInputChanged = (inputIndex: number) => {
    const input = inputRefs.current[inputIndex];
    const prevInput = inputRefs.current[inputIndex - 1];
    const nextInput = inputRefs.current[inputIndex + 1];
    if (!input) return;
    const newValue = input.value;
    const isDeletion = newValue === '';
    const wasEmpty = verificationCode[inputIndex] === '';
    const verificationCodeCopy = [...verificationCode];
    /**
     * Reasons for input change:
     * 1. User inputs a key on empty field
     * 2. User inputs a key on an already filled field (newValue.length === 2, replace current input with new value and focus next input)
     * 3. User pastes from SMS (inputIndex = 0, newValue.length > 1 || newValue.length === inputLength)
     * 4. User deletes on a filled field (delete and focus previous field)
     * 5. User deletes on an empty field (handled by onKeyDown)
     */

    if (isDeletion) {
      if (prevInput) prevInput.focus();
      verificationCodeCopy[inputIndex] = '';
      setVerificationCode(verificationCodeCopy);
      return;
    }
    // Validate new input
    if (!Number.isFinite(Number(newValue)) && !allowLetters) {
      return;
    }
    if (allowLetters && (!alphaNumericRegex.test(newValue) || newValue.length > 1)) {
      return;
    }
    // Update state
    if (isError && clearVerificationCodeError) clearVerificationCodeError();

    if (newValue.length === 1) {
      verificationCodeCopy[inputIndex] = newValue;
      nextInput?.focus();
      setVerificationCode(verificationCodeCopy);
    } else if (
      // Paste any number of chars, only for first input and if it was empty
      (newValue.length > 1 && wasEmpty && inputIndex === 0) ||
      // Replace all inputs with pasted code
      newValue.length === inputLength
    ) {
      insertPastedCode(newValue);
    } else {
      verificationCodeCopy[inputIndex] = newValue.slice(-1);
      setVerificationCode(verificationCodeCopy);
      nextInput?.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    const userInput = e.key;

    if (isDisabled) {
      return;
    }

    if (userInput === 'Enter' && onSubmit && !isVerificationCodeInvalid() && !isLoading) {
      onSubmit(verificationCode.join(''));
    }

    if (userInput === 'ArrowLeft') {
      inputRefs.current[index - 1]?.focus();
      return;
    }
    if (userInput === 'ArrowRight') {
      inputRefs.current[index + 1]?.focus();
    }
    if (userInput === 'Backspace' && verificationCode[index] === '') {
      inputRefs.current[index - 1]?.focus();
      e.preventDefault();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedCode = e.clipboardData.getData('Text');
    insertPastedCode(pastedCode);
  };

  useEffect(() => onChange(verificationCode.join('')), [verificationCode, onChange]);

  useEffect(() => {
    if (submitAutomatically && !isVerificationCodeInvalid() && onSubmit) {
      onSubmit(verificationCode.join(''));
    }
  }, [submitAutomatically, onSubmit, verificationCode, isVerificationCodeInvalid]);

  useEffect(() => {
    if (clearVerificationCode) {
      setVerificationCode([...Array(inputLength)].map(() => ''));
    }
  }, [clearVerificationCode, inputLength]);

  useEffect(() => {
    if (isError) {
      setShouldFocusFirstInvalidField(true);
    } else {
      setShouldFocusFirstInvalidField(false);
    }
  }, [initialValue, isError, setShouldFocusFirstInvalidField]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (autoFocus && inputRefs.current) {
        inputRefs.current[0]?.focus();
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [autoFocus]);

  return (
    <>
      <VerificationCodeInputContainer ref={formContainerRef} align="center" style={{ ...style }}>
        {verificationCode.map((code, i) => (
          <StyledInput
            isError={isError}
            dataQa={`verificationCodeInput${i}`}
            inputMode="numeric" // Provides an additional hint to show a numeric keypad
            type="tel" // Ensures numeric input and shows a numeric keypad on mobile devices
            pattern="[0-9]"
            autoComplete="one-time-code"
            aria-invalid={!!isError}
            aria-required={ariaRequired}
            aria-describedby={ariaDescribedBy && isError ? ariaDescribedBy : undefined}
            ref={(ref) => {
              if (ref) inputRefs.current[i] = ref;
            }}
            aria-label={`Input verification code ${i + 1}`}
            // Keep this as inputLength to allow user pasting from SMS
            maxLength={inputLength}
            max={9}
            min={0}
            value={code}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            onKeyDown={(e) => handleKeyDown(e, i)}
            onInput={() => onInputChanged(i)}
            onPaste={handlePaste}
            wrappedInputProps={{ containerStyle: { width: 50 } }}
          />
        ))}
      </VerificationCodeInputContainer>
    </>
  );
};

export default VerificationCodeInput;
